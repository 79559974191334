<template>
    <div id="container" class="scrollbar-template" style="height:100%; overflow-y:scroll; background-color:#ffffff">
        <div class="header" v-show="cargaModal">
            <img :src="imagenModal" style="width: 80px; height: 80px;"/>
            <h2 class="titleModal">{{ title }}</h2>
        </div>
        <div id="loading" v-show="!cargaModal">
            
                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
            
        </div>

        <ValidationObserver v-show="cargaModal" ref="form" v-slot="{ invalid, validated  }">
            <form-wizard @onSubmit="store" @onCancel="cancel" @onSaveDraft="storeDraft" @scrollTop="scrollTop" :selectedIndex="tabIndex" :isWizard="action != 'update' ? true:false" :showDraft='true' :loadingGuardar="loadingGuardar">
                <tab name="Datos generales" :selected="true">
                    <div id="form-step-1" class="mt-8" >
                        <v-row>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                    <v-text-field 
                                        outlined
                                        label="Nombre(s)" 
                                        class="pa-0 ma-0" 
                                        v-model="empleado.nombre" 
                                        :error-messages="errors" 
                                        required
                                        id="txtNombre"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Apellido Paterno" rules="required">
                                    <v-text-field 
                                        outlined
                                        label="Apellido Paterno" 
                                        class="pa-0 ma-0" 
                                        v-model="empleado.apellido_paterno" 
                                        :error-messages="errors" 
                                        required
                                        id="txtApellidoPaterno"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                
                                    <v-text-field 
                                        outlined
                                        label="Apellido Materno"
                                        class="pa-0 ma-0" 
                                        v-model="empleado.apellido_materno" 
                                        id="txtApellidoMaterno"
                                    >
                                    </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Nacionalidad"
                                    v-model="empleado.nacionalidad"
                                    class="pa-0 ma-0"
                                    id="txtNacionalidad"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_nacimiento"
                                    label="Fecha de nacimiento"
                                    placeholder="dd/mm/aaaa"
                                    rules="required"
                                    ref="FechaNacimiento"
                                />
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Lugar de nacimiento"
                                    v-model="empleado.lugar_nacimiento"
                                    class="pa-0 ma-0"
                                    id="txtLugarNacimiento"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="5" class="pt-0 pb-0 ma-0">
                                <template>
                                    <v-container class="ma-0 pa-0" fluid>
                                        <h4 class="tituloRadio">Sexo</h4>
                                        <v-radio-group class="ma-0 pa-0" v-model="empleado.sexo" row>
                                        <v-radio label="Masculino" value="M"></v-radio>
                                        <v-radio label="Femenino" value="F"></v-radio>
                                        </v-radio-group>
                                    </v-container>
                                </template>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <template>
                                    <v-container class="ma-0 pa-0" fluid>
                                        <h4 class="tituloRadio">Estado civil</h4>
                                        <v-radio-group class="ma-0 pa-0" v-model="empleado.estado_civil_id" row>
                                        <v-radio label="Soltero" :value='1'></v-radio>
                                        <v-radio label="Casado" :value='2'></v-radio>
                                        </v-radio-group>
                                    </v-container>
                                </template>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" class="mt-3">
                                <v-container class="ma-0 pa-0" fluid>
                                    <v-checkbox class="pa-0 ma-0" v-model="empleado.tiene_hijos" label="Tiene Hijo(s)"></v-checkbox>
                                </v-container>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field
                                    outlined
                                    label="Código postal"
                                    persistent-hint
                                    v-model="busca_cp"
                                    class="pa-0 ma-0"
                                    @keyup.enter="busca_codigos()"
                                    v-mask="'#####'"
                                    id="txtCP"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field
                                    outlined
                                    label="Estado"
                                    v-model="estado"
                                    disabled
                                    class="pa-0 ma-0"
                                    id="txtEstado"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field
                                    outlined
                                    label="Municipio"
                                    v-model="municipio"
                                    disabled
                                    class="pa-0 ma-0"
                                    id="txtMunicipio"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Dirección" rules="required">
                                    <v-text-field 
                                        outlined
                                        label="Dirección" 
                                        class="pa-0 ma-0" 
                                        v-model="empleado.direccion" 
                                        :error-messages="errors" 
                                        required
                                        id="txtDireccion"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Colonia"
                                    :items="colonias"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.colonia_id"
                                    class="pa-0 ma-0"
                                    id="txtColonia"
                                >
                                    <template v-if="mColonia"  v-slot:append-item>
                                        <v-list-item>             
                                            <v-list-item-content class="cursor-pointer"  @click="abrirModalColonia()">
                                                <v-list-item-title style="color:#007EFF">Agregar colonia</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Teléfono"
                                    class="pa-0 ma-0"
                                    v-mask="'(###) ###-####'"
                                    placeholder="(999) 999-9999"
                                    v-model="empleado.telefono"
                                    id="txtTelefono"
                                >    
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Celular"
                                    class="pa-0 ma-0"
                                    v-mask="'(###) ###-####'"
                                    placeholder="(999) 999-9999"
                                    v-model="empleado.celular"
                                >    
                                </v-text-field>
                            </v-col>
                            <v-col v-if="!empleado.baja" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Correo eléctronico" rules="email" >
                                    <v-text-field 
                                        outlined
                                        label="Correo elétronico" 
                                        class="pa-0 ma-0" 
                                        v-model="empleado.correo" 
                                        :error-messages="errors" 
                                        required
                                        id="txtEmail"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Escolaridad"
                                    :items="nivelesEstudios"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.nivel_estudios_id"
                                    class="pa-0 ma-0"
                                    id="txtEscolaridad"
                                >
                                </v-autocomplete>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Profesión"
                                    class="pa-0 ma-0"
                                    id="txtConocimientosExtra"
                                    v-model="empleado.conocimientos_extras"
                                >
                                </v-text-field>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Domicilio donde se prestarán los servicios"
                                    class="pa-0 ma-0"
                                    v-model="empleado.direccion_laboral"
                                    id="txtDomicilioServicios"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                <ValidationProvider v-slot="{ errors }" name="Domicilio del contrato" rules="required">
                                    <v-textarea
                                        v-model="empleado.direccion_contrato"
                                        outlined
                                        label="Domicilio del contrato"
                                        rows="1"
                                        required 
                                        :error-messages="errors" 
                                    >
                                        <template v-slot:append-outer>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pl-2">info_outline</v-icon>
                                                </template>
                                                <span class="textTooltip">
                                                    Verifica que la dirección del contrato sea la <br>
                                                    correcta. Este campo podrá ser editado.
                                                </span>
                                            </v-tooltip>
                                        </template>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                <ValidationProvider v-slot="{ errors }" name="Ubicación laboral" rules="required">
                                    <v-text-field
                                        v-model="empleado.ubicacion_laboral"
                                        outlined
                                        label="Ubicación laboral"
                                        rows="1"
                                        required 
                                        :error-messages="errors" 
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <template>
                                <v-col cols="12" xs="12" md="12" class="mt-3">
                                    <h4 class="tituloRadio">Puntos adicionales</h4>
                                </v-col>
                                <!-- Campos para el ranking -->
                                <v-col cols="12" xs="12" md="6" class="mt-3">
                                    <v-container class="ma-0 pa-0" fluid>
                                        <v-switch
                                            v-if="empleado.ranking != null"
                                            class="pa-0 ma-0"
                                            v-model="empleado.ranking.clima_laboral"
                                            label="Puntos por clima laboral"
                                        ></v-switch>
                                    </v-container>
                                </v-col>

                                <v-col cols="12" xs="12" md="6" class="mt-3">
                                    <v-container class="ma-0 pa-0" fluid>
                                        <v-switch
                                            v-if="empleado.ranking != null"
                                            class="pa-0 ma-0"
                                            v-model="empleado.ranking.proyectos_mejoras"
                                            label="Puntos por mejora proyectos"
                                        ></v-switch>
                                    </v-container>
                                </v-col>
                                <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4" class="py-0">
                                    <v-menu offset-y elevation="0" content-class="menuClassFoto" z-index="999">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-bind="attrs"
                                                v-on="on"
                                                v-model="archivo_adjunto"
                                                outlined
                                                label="Foto empleado"
                                                type="text"
                                                hide-details
                                                readonly
                                            > 
                                                <template slot="append">
                                                    <v-icon v-bind="attrs" v-on="on">arrow_drop_down</v-icon>
                                                </template>
                                            </v-text-field>                                                                    
                                        </template>
                                        <v-text-field
                                            outlined
                                            id="form"
                                            readonly
                                            label="Adjuntar desde galería"
                                            @click="almacenarImagen"
                                            v-model="nombreImagen"
                                            clearable
                                            hide-details
                                            class="pt-2 v-text-field-foto"
                                        >
                                            <template slot="append">
                                                <v-icon class="tamIcono">attach_file</v-icon>
                                            </template>
                                        </v-text-field>
                                        <input
                                            type="file"
                                            style="display: none"
                                            ref="imagen"
                                            accept="image/*"
                                            id="avatar"
                                            @change="onFilePickedImagen"
                                        />
                                    
                                    
                                        <v-text-field
                                            outlined
                                            id="form2"
                                            readonly
                                            label="Tomar fotografía"
                                            @click="abrirModalFoto('capturar')"
                                            v-model="nombreFoto"
                                            clearable
                                            hide-details
                                            class="pt-2 v-text-field-foto"
                                        >
                                            <template slot="append">
                                                <v-icon class="tamIcono invertirColor" >camera_alt</v-icon>
                                            </template>
                                        </v-text-field>
                                    </v-menu>
                                </v-col>
                                 
                                <v-col v-if="action === 'create'" cols="12" xs="12" sm="4" md="4" class="py-0">
                                    <v-avatar>
                                        <img id="img" src="/static/avatar/usuario4.jpg" width="130px" />
                                    </v-avatar>
                                </v-col>
                                <v-col v-else cols="12" xs="12" sm="4" md="4" class="py-0">
                                    <v-avatar>
                                        <img id="img" :src="nombreFotoUptade" width="130px" />
                                    </v-avatar>
                                </v-col>
                            </template>
                                                    
                        </v-row>

                        <v-divider class="my-10"></v-divider>
                        <h3 class="subtitle mt-7 ml-2">Cuentas Bancarias</h3>

                        <v-row>
                            <v-col cols="12" xs="12" md="4" class="my-8">
                                <v-text-field
                                    outlined
                                    label="Cuenta bancaria"
                                    v-model="cuenta" 
                                    id="cuenta"
                                    v-mask="'########################'"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" md="4" class="my-8">
                                <v-text-field 
                                    outlined
                                    label="Clabe interbancaria" 
                                    maxLength="18"
                                    v-model="clabe"
                                    id="clabe"
                                    v-mask="'##################'"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" md="3" class="mt-8">
                                <v-autocomplete
                                    outlined
                                    label="Banco"
                                    :items="bancos"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="banco_id"
                                    id="txtBanco"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-btn color="#1E2245" class="mt-12" elevation="0" dark fab  @click="agregarCuenta()" small>
                                <v-icon dark>add</v-icon>
                            </v-btn>
                            
                            <template v-for="(i,index) in cuentasBancarias">
                                <v-col cols="12" xs="12" sm="12" md="3" :key="index" class="pt-0 pb-0 ma-0">
                                    <v-autocomplete 
                                    id="file_input_file" 
                                    label="Banco" 
                                    outlined 
                                    :disabled="(indexBanco == index && isDisabled == false)? false : true" 
                                    :items="bancos"
                                    item-text="nombre"
                                    item-value="id"
                                    :value="i.banco_id"
                                    v-model="i.banco_id"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="3" :key="'AB'+index" class="pt-0 pb-0 px-0 ma-0">
                                    <v-text-field 
                                    type="text" 
                                    label="Cuenta bancaria" 
                                    outlined 
                                    :value="i.cuenta_bancaria" 
                                    v-model="i.cuenta_bancaria"
                                    v-mask="'########################'"
                                    :disabled="(indexBanco == index && isDisabled == false)? false : true"  ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="3" :key="'BB'+index" class="pt-0 pb-0 pr-0 ma-0">
                                    <v-text-field 
                                    type="text" 
                                    label="Clabe interbancaria" 
                                    outlined 
                                    :value="i.clabe_bancaria"
                                    v-model="i.clabe_bancaria"
                                    v-mask="'##################'" 
                                    :disabled="(indexBanco == index && isDisabled == false)? false : true"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="1" :key="'CB'+index" class="pt-0 pb-0">
                                    <v-switch class="mb-1 mt-2" v-model="i.activo" :disabled="i.activo" @change="cuentaActiva(index)"></v-switch>
                                </v-col>
                                <v-btn class="botonHover mt-1 ml-2" icon fab dark small :key="'DB'+index" @click="editaCuenta(index, i)" >
                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                </v-btn>
                                <v-btn class="botonHover mt-1 ml-2" icon fab dark small :key="'EB'+index" @click="eliminaCuenta(index, i)" >
                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                </v-btn>
                            </template>
                        </v-row>
                    </div>
                </tab>

                <tab name="Seguridad social">
                    <div id="form-step-2" class="mt-8">
                        <v-row>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0" v-if="requeridoNSS">
                                <ValidationProvider v-slot="{ errors }" name="NSS" rules="required">
                                    <v-text-field 
                                        outlined
                                        label="NSS" 
                                        class="pa-0 ma-0" 
                                        v-model="empleado.imss"
                                        maxlength="11"
                                        :error-messages="errors" 
                                        required
                                        v-mask="'###########'"
                                        id="txtIMSS"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0" v-else>
                                <v-text-field 
                                    outlined
                                    label="NSS" 
                                    class="pa-0 ma-0" 
                                    v-model="empleado.imss"
                                    id="txtIMSS"
                                    maxlength="11"
                                    v-mask="'###########'"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="RFC" rules="required|rfc">
                                    <v-text-field 
                                        outlined
                                        label="RFC" 
                                        class="pa-0 ma-0" 
                                        maxlength="13"
                                        v-model="empleado.rfc"
                                        :error-messages="errors" 
                                        required
                                        id="txtRFC"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="CURP" rules="required|curp">
                                    <v-text-field 
                                        outlined
                                        label="CURP" 
                                        maxlength="18"
                                        class="pa-0 ma-0" 
                                        v-model="empleado.curp"
                                        :error-messages="errors" 
                                        required
                                        id="txtCURP"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Tipo de sangre" 
                                    class="pa-0 ma-0" 
                                    v-model="empleado.tipo_sangre"
                                    id="txtTipoSangre"
                                >   
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field
                                    outlined
                                    label="En caso de emergencia avisar a"
                                    class="pa-0 ma-0"
                                    v-model="empleado.nombre_contacto"
                                >   
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field
                                    outlined
                                    label="Teléfono de contacto"
                                    v-mask="'(###) ###-####'"
                                    placeholder="(999) 999-9999"
                                    class="pa-0 ma-0"
                                    v-model="empleado.telefono_contacto"
                                >   
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Clave elector" rules="required">
                                    <v-text-field 
                                        outlined
                                        label="Clave elector" 
                                        class="pa-0 ma-0" 
                                        v-model="empleado.clave_elector"
                                        :error-messages="errors" 
                                        required
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                <v-textarea 
                                    outlined
                                    label="Observaciones de salud"
                                    class="pa-0 ma-0"
                                    name="input-7-4"
                                    v-model="empleado.observacion_salud"
                                    rows="1"
                                    id="txtObservaciones"
                                >
                                </v-textarea>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0">
                                <v-divider class="mt-5 mb-10"></v-divider>
                                <h3 class="subtitle ml-2 mb-3">Datos de empresa y puesto</h3>
                            </v-col>


                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Cliente"
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        persistent-hint
                                        v-model="cliente_value"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors" 
                                        id="txtCliente"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Empresa"
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empresa_value"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors" 
                                        id="txtEmpresa"
                                        ref="autoEmpresa"
                                    >
                                        <template v-if="mEmpresa"  v-slot:append-item>
                                            <v-list-item>             
                                                <v-list-item-content class="cursor-pointer"  @click="abrirModalAgregar('tEmpresa')">
                                                    <v-list-item-title style="color:#007EFF">Agregar empresa</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Sucursal" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Sucursal"
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        persistent-hint
                                        v-model="sucursal_value"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors"
                                        id="txtSucursal"
                                        ref="autoSucursal"
                                    >
                                        <template v-if="mSucursal"  v-slot:append-item>
                                            <v-list-item>             
                                                <v-list-item-content class="cursor-pointer"  @click="abrirModalAgregar('tSucursal')">
                                                    <v-list-item-title style="color:#007EFF">Agregar sucursal</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Departamento"
                                    :items="departamentos"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.departamento_id"
                                    class="pa-0 ma-0"
                                    id="txtDepartamento"
                                    ref="autoDepartamento"
                                >
                                    <template v-if="mDeptos"  v-slot:append-item>
                                        <v-list-item>             
                                            <v-list-item-content class="cursor-pointer"  @click="abrirModalAgregar('tDepto')">
                                                <v-list-item-title style="color:#007EFF">Agregar departamento</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'admin-sucursal'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Departamento"
                                    :items="departamentos"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.departamento_id"
                                    class="pa-0 ma-0"
                                    id="txtDepartamento"
                                    ref="autoDepartamento"
                                >
                                    <template v-if="mDeptos"  v-slot:append-item>
                                        <v-list-item>             
                                            <v-list-item-content class="cursor-pointer"  @click="abrirModalAgregar('tDepto')">
                                                <v-list-item-title style="color:#007EFF">Agregar departamento</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Horario"
                                    :items="horarios"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.horario_id"
                                    class="pa-0 ma-0"
                                    id="txtHorario"
                                    ref="autoHorario" 
                                >
                                    <template v-if="mReloj"  v-slot:append-item>
                                        <v-list-item>             
                                            <v-list-item-content class="cursor-pointer"  @click="abrirModalAgregar('tHorario')">
                                                <v-list-item-title style="color:#007EFF">Agregar horario</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Días de vacaciones" 
                                    class="pa-0 ma-0"
                                    v-model="empleado.dias_vacaciones" 
                                    v-mask="'###'"
                                    id="txtDiasVacaciones"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Días de vacaciones fiscales" 
                                    class="pa-0 ma-0"
                                    v-model="empleado.vacaciones_fiscales" 
                                    v-mask="'###'"
                                    id="txtDiasVacacionesFiscales"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Clave"
                                    class="pa-0 ma-0"
                                    v-model="empleado.clave_interna"
                                    id="txtClaveInterna"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Puesto" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Puesto"
                                        :items="puestos"
                                        item-text="claveNombre"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.puesto_id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors"
                                        ref="autoPuesto"
                                    >
                                    <template v-if="mPuesto"  v-slot:append-item>
                                        <v-list-item>             
                                            <v-list-item-content class="cursor-pointer"  @click="abrirModalAgregar('tPuesto')">
                                                <v-list-item-title style="color:#007EFF">Agregar puesto</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>  
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Entidad CFDI"
                                    :items="estadosCFDI"
                                    item-text="nombre"
                                    item-value="valor"
                                    persistent-hint
                                    v-model="empleado.entidad_cfdi"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Registro Patronal" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Registro Patronal"
                                        :items="registroPatronal"
                                        item-text="nombreClave"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.registro_patronal_id"
                                        class="pa-0 ma-0"
                                        required 
                                        id="txtPatronal"
                                        :error-messages="errors" 
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="(empleado.baja == false) || (empleado.baja == true && empleado.fecha_cobro_finiquito == null)" cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="empleado.fecha_ingreso"
                                    :valorDefecto="empleado.fecha_ingreso"
                                    label="Fecha de ingreso"
                                    placeholder="dd/mm/aaaa"
                                    rules="required"
                                    ref="FechaIngreso"
                                />
                            </v-col>
                            <v-col v-if="empleado.baja == true && empleado.fecha_cobro_finiquito != null" cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="empleado.fecha_ingreso"
                                    :valorDefecto="empleado.fecha_ingreso"
                                    label="Fecha de ingreso"
                                    placeholder="dd/mm/aaaa"
                                    rules="required"
                                    ref="FechaIngreso"
                                    disabled
                                />
                            </v-col>
                            <v-col v-if="(empleado.baja == false) || (empleado.baja == true && empleado.fecha_cobro_finiquito == null)" cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="empleado.fecha_alta_imss"
                                    :valorDefecto="empleado.fecha_alta_imss"
                                    label="Fecha alta IMSS"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaImss"
                                    rules="required"
                                />
                            </v-col>
                            <v-col v-if="empleado.baja == true && empleado.fecha_cobro_finiquito != null" cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="empleado.fecha_alta_imss"
                                    :valorDefecto="empleado.fecha_alta_imss"
                                    label="Fecha alta IMSS"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaImss"
                                    rules="required"
                                    disabled
                                />
                            </v-col>
                            <v-col v-if="empleado.baja && empleado.fecha_cobro_finiquito == null" cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="empleado.fecha_baja"
                                    :valorDefecto="empleado.fecha_baja"
                                    label="Fecha de baja"
                                    placeholder="dd/mm/aaaa"
                                    rules="required"
                                />
                            </v-col>
                            <v-col v-if="empleado.baja && empleado.fecha_cobro_finiquito != null" cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="empleado.fecha_baja"
                                    :valorDefecto="empleado.fecha_baja"
                                    label="Fecha de baja"
                                    placeholder="dd/mm/aaaa"
                                    rules="required"
                                    disabled
                                />
                            </v-col>
                            <v-col v-if="empleado.baja && empleado.fecha_cobro_finiquito == null" cols="12" xs="12" sm="3" md="3" class="py-0">
                                <ValidationProvider v-slot="{ errors }" name="Tipo de baja" rules="required">
                                    <v-autocomplete
                                        outlined
                                        :items="tipos_baja_imss"
                                        item-text="descripcion"
                                        item-value="id"
                                        label="Tipo de baja ante el IMSS"
                                        persistent-hint
                                        v-model="tipo_baja_imss_id"
                                        :error-messages="errors" 
                                        required
                                    ></v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="empleado.baja && empleado.fecha_cobro_finiquito != null" cols="12" xs="12" sm="3" md="3" class="py-0">
                                <ValidationProvider v-slot="{ errors }" name="Tipo de baja" rules="required">
                                    <v-autocomplete
                                        outlined
                                        :items="tipos_baja_imss"
                                        item-text="descripcion"
                                        item-value="id"
                                        label="Tipo de baja ante el IMSS"
                                        persistent-hint
                                        v-model="tipo_baja_imss_id"
                                        :error-messages="errors" 
                                        required
                                        disabled
                                    ></v-autocomplete>
                                </ValidationProvider>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo contrato sat"
                                    :items="tiposContratos"
                                    item-text="claveDescripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="contrato_sat_id"
                                    class="pa-0 ma-0"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Tipo contrato" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo contrato"
                                        :items="contratos"
                                        item-text="claveDescripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.tipo_contrato_id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors" 
                                        id="txtTipoContrato"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="obra_determinada" xs="12" sm="6" md="6" class="py-0 my-0" >
                                <ValidationProvider v-slot="{ errors }" name="Días obra" rules="required">
                                    <v-text-field
                                        outlined
                                        label="Días de duración"
                                        persistent-hint
                                        v-model="empleado.dias_contrato"
                                        class="pa-0 ma-0"
                                        type="number"
                                        required
                                        :error-messages="errors"
                                    >
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col v-if="determinado" xs="12" sm="12" md="12" class="py-0 my-0 mt-3 radio_group_01 radio-contrato" >
                                <h4 class="tituloRadio ml-2">Elige la vigencia del contrato</h4>
                                <ValidationProvider v-slot="{ errors }" name="Días contrato" rules="required">
                                    <v-radio-group class="ml-2" v-model.number="empleado.dias_contrato" row required :error-messages="errors" >
                                        <v-radio class="mb-4 radio-label" label="30 días" :value="30"></v-radio>
                                        <v-radio class="mb-4 radio-label" label="60 días" :value="60"></v-radio>
                                        <v-radio class="mb-4 radio-label" label="90 días" :value="90"></v-radio>
                                        <v-radio class="mb-4 radio-label" label="180 días" :value="180"></v-radio>
                                    </v-radio-group>
                                </ValidationProvider>
                            </v-col>
                            
                            <v-col v-if="activar_dos_contratos &&  action=='create'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Segundo contrato" rules="required">
                                    <v-autocomplete
                                        outlined 
                                        label="Segundo contrato"
                                        :items="contratos"
                                        item-text="claveDescripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.segundo_contrato_id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors" 
                                        id="txtTipoContratoDos"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col v-if="activar_dos_contratos &&  action=='update'" cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined 
                                    label="Segundo contrato"
                                    :items="contratos"
                                    item-text="claveDescripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.segundo_contrato_id"
                                    class="pa-0 ma-0"
                                    id="txtTipoContratoDos"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-divider class="mt-5 mb-10"></v-divider>
                        <h3 class="subtitle ml-2">Tipo de nómina y contratación</h3>

                        <v-row class="pt-0 mt-n5 mb-0">
                            <v-col cols="12" xs="12" sm="6" md="6" class=" pb-0 mt-8">
                                <ValidationProvider v-slot="{ errors }" name="Tipo nómina" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo de nómina"
                                        :items="itemsTiposNominas"
                                        item-text="claveDescripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.tipo_nomina_id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors" 
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class=" pb-0 mt-8">
                                <ValidationProvider v-slot="{ errors }" name="Régimen de contratación" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Régimen de contratación"
                                        :items="tiposRegimenesSAT"
                                        v-model="empleado.tipo_regimen_sat_id"
                                        item-text="claveDescripcion"
                                        item-value="id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors" 
                                        no-data-text="Datos no disponibles"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="8" md="8" class="py-0">
                                <ValidationProvider v-slot="{ errors }" name="Régimen Fiscal" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Régimen Fiscal"
                                        :items="regimenesFiscales"
                                        item-text="descripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.regimen_fiscal_id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors" 
                                        no-data-text="Datos no disponibles"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="4" md="4" class="pt-0 pb-0 ma-0">
                                <v-text-field
                                    outlined
                                    label="Código postal del timbre"
                                    persistent-hint
                                    v-model="empleado.codigoPostalTimbre.valor"
                                    class="pa-0 ma-0"
                                    v-mask="'#####'"
                                    id="txtCPtimbre"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Prestaciones"
                                    :items="prestaciones"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.prestacion_id"
                                    class="pa-0 ma-0"
                                >   
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="action == 'create'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyMoneyValidate
                                    rules="required"
                                    outlined
                                    label="Sueldo diario"
                                    class="pa-0 ma-0"
                                    prefix="$"
                                    v-model="empleado.salarioMinimo.nuevo_sueldo"
                                    v-bind:options="options"
                                    ref="salario_diario"
                                    :readonly="action == 'update'? true:false"
                                >
                                    <template v-if="action == 'update'" v-slot:append-icon>
                                        <v-btn
                                            class="elevation-0 ma-0 pa-0 v-btn-hover"
                                            color="transparent"
                                            @click="abrirModalSueldo('sueldo')"
                                            rounded
                                            :ripple="false" 
                                        >
                                            <v-icon color="white" class="tamIconoBoton invertirColorBotones">
                                                edit
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                </VuetifyMoneyValidate>
                            </v-col>
                            <v-col v-if="action == 'update'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyMoneyValidate
                                    rules="required"
                                    outlined
                                    label="Sueldo diario"
                                    class="pa-0 ma-0"
                                    prefix="$"
                                    v-model="empleado.salarioMinimo.nuevo_sueldo"
                                    v-bind:options="options"
                                    ref="salario_diario"
                                    :readonly="action == 'update'? true:false"
                                >
                                    <template v-if="(empleado.baja == false) || (empleado.baja == true && empleado.fecha_cobro_finiquito == null)" v-slot:append-icon>
                                        <v-btn
                                            class="elevation-0 ma-0 pa-0 v-btn-hover"
                                            color="transparent"
                                            @click="abrirModalSueldo('sueldo')"
                                            rounded
                                            :ripple="false" 
                                        >
                                            <v-icon color="white" class="tamIconoBoton invertirColorBotones">
                                                edit
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                </VuetifyMoneyValidate>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyMoney
                                   outlined
                                    label="Sueldo mensual"
                                    prefix="$"
                                    type = 'number'
                                    v-model="sueldoMensual"
                                    class="pa-0 ma-0"
                                    disabled
                                    v-bind:options="options"
                                    ref="salario_mensual"
                                />
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Tipo de salario IMSS" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo de salario IMSS"
                                        :items="itemsTipoSalarioIMSS"
                                        item-text="descripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.tipo_salario_imss_id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>

                            <template v-if="action == 'create'">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0 ">
                                    <VuetifyMoneyValidate
                                        outlined
                                        label="Salario base cotización fijo"
                                        class="pa-0 ma-0"
                                        prefix="$"
                                        v-model="empleado.salarioMinimo.nuevo_sbc_fijo"
                                        v-bind:options="options"
                                        :disabled="disableSBCF"
                                        @onBlur="consultarPreSBCF('manual')"
                                    >
                                        <template v-slot:append-icon>
                                            <v-btn
                                                class="elevation-0 ma-0 pa-0 v-btn-hover"
                                                color="transparent"
                                                @click="consultarPreSBCF('automatico')"
                                                rounded
                                                :ripple="false" 
                                                :loading="loadingPreSBCF"
                                            >
                                                <v-icon color="#828282" class="tamIconoBoton" v-text="'$calculate_outline'"></v-icon>
                                            </v-btn>
                                        </template>
                                    </VuetifyMoneyValidate>                             
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0 ">
                                    <VuetifyMoneyValidate
                                        outlined
                                        label="Salario base cotización variable"
                                        class="pa-0 ma-0"
                                        prefix="$"
                                        v-model="empleado.salarioMinimo.nuevo_sbc_variable"
                                        v-bind:options="options"
                                        v-bind:showAppendOuter="true"
                                        v-bind:textTooltip="'Ingrese un monto estimado bimestral de los ingresos variables del trabajador, <br>para el calculo del Salario Base Cotización. Este monto puede ser modificado posteriormente.'"
                                        :disabled="disableSBCV"
                                        @onBlur="consultarPreSBCF('manual')"
                                    >
                                    </VuetifyMoneyValidate>                             
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0 ">
                                    <VuetifyMoneyValidate
                                        outlined
                                        label="Salario base cotización"
                                        class="pa-0 ma-0"
                                        prefix="$"
                                        v-model="sbc_consulta"
                                        v-bind:options="options"
                                        readonly
                                    >
                                    </VuetifyMoneyValidate>                             
                                </v-col>
                            </template>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0 " v-if="action == 'update'">
                                    <VuetifyMoneyValidate
                                        outlined
                                        label="Salario base cotización"
                                        class="pa-0 ma-0"
                                        prefix="$"
                                        v-model="empleado.salarioMinimo.nuevo_sbc"
                                        v-bind:options="options"
                                        v-bind:showAppendOuter="true"
                                        v-bind:textTooltip="'En caso de no ingresar un valor, Cliker calculará el <br>Salario base cotización tomando en cuenta la <br>fecha actual y la prestación seleccionada.'"
                                        :readonly="action == 'update'? true:false"
                                    >
                                        <template v-if="(empleado.baja == false) || (empleado.baja == true && empleado.fecha_cobro_finiquito == null)" v-slot:append-icon>
                                            <v-btn
                                                class="elevation-0 ma-0 pa-0 v-btn-hover"
                                                color="transparent"
                                                @click="abrirModalSueldo('SBC')"
                                                rounded
                                                :ripple="false" 
                                            >
                                                <v-icon color="white" class="tamIconoBoton invertirColorBotones">
                                                    edit
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                    </VuetifyMoneyValidate>                             
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Tipo de empleado IMSS" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo de empleado IMSS"
                                        :items="itemsTipoTrabajadorIMSS"
                                        item-text="descripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.tipo_trabajador_imss_id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors"
                                    >
                                        <template v-slot:append-outer>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pl-2">info_outline</v-icon>
                                                </template>
                                                <span class="textTooltip">
                                                    Este campo será utilizado para los cálculos del IMSS
                                                </span>
                                            </v-tooltip>
                                        </template>
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <ValidationProvider v-slot="{ errors }" name="Semana IMSS" rules="required">
                                    <v-autocomplete
                                        outlined
                                        label="Semana IMSS"
                                        :items="itemsSemanaIMSS"
                                        item-text="descripcion"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.semana_imss_id"
                                        class="pa-0 ma-0"
                                        required 
                                        :error-messages="errors"
                                    >
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo de empleado"
                                    :items="itemsTipoEmpleado"
                                    item-text="descripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.empleado_tipo_id"
                                    class="pa-0 ma-0"
                                >
                                    <template v-slot:append-outer>
                                        <v-tooltip top class="pb-7">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color='#96999A' v-on="on" slot="activator" class="pl-2">info_outline</v-icon>
                                            </template>
                                            <span class="textTooltip">
                                                Este campo será utilizado para los cálculos del costo y <br>
                                                retención de seguridad social del trabajador
                                            </span>
                                        </v-tooltip>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Forma de pago"
                                    :items="itemsFormaDePago"
                                    item-text="nombre"
                                    item-value="valor"
                                    persistent-hint
                                    v-model="empleado.tipo_pago"
                                    class="pa-0 ma-0"
                                >   
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Categoría empleado"
                                    :items="itemsCategoriasEmpleado"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado.empleado_categoria_id"
                                    class="pa-0 ma-0"
                                >   
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Centro de costo"
                                    :items="itemsCentroCosto"
                                    item-text="descripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="centroCostoId"
                                    class="pa-0 ma-0"
                                >   
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <VuetifyMoney
                                   outlined
                                    label="Otros ingresos"
                                    prefix="$"
                                    type = 'number'
                                    v-model="empleado.otros_ingresos"
                                    class="pa-0 ma-0"
                                    v-bind:options="options"
                                    ref="otros_ingresos"
                                />
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0">
                                <v-divider class="mt-5 mb-10"></v-divider>
                                <h3 class="subtitle ml-2 mb-3">Prestaciones</h3>
                            </v-col>



                            <template v-if="(empleado.baja == false) || (empleado.baja == true && empleado.fecha_cobro_finiquito == null)">
                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <v-text-field
                                        outlined
                                        label="Número INFONAVIT"
                                        class="pa-0 ma-0"
                                        v-model="empleado.cuenta_infonavit"
                                        maxlength="10"
                                        v-mask="'##########'"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <VuetifyDatePicker
                                        outlined
                                        v-model="empleado.fecha_inicio_descuento"
                                        label="Fecha inicio descuento"
                                        placeholder="dd/mm/aaaa"
                                        ref="FechaDescuento"
                                        :disabled="showInfonavit"
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo descuento"
                                        :items="factores"
                                        v-model="empleado.tipo_factor"
                                        item-text="nombre"
                                        item-value="valor"
                                        persistent-hint
                                        :disabled="showInfonavit"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col v-if="empleado.tipo_factor === 'CF'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <VuetifyMoney
                                        v-model="empleado.factor_descuento"
                                        label="Cantidad a descontar"
                                        outlined 
                                        v-bind:options="options"
                                        ref="factor_descuento"
                                        :disabled="showInfonavit"
                                    />
                                </v-col>
                                <v-col v-if="empleado.tipo_factor === 'FD'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-text-field
                                        outlined
                                        label="Cantidad a descontar (VSM)"
                                        min=0
                                        max=10000
                                        v-on:keypress="presicionNumero($event,empleado.factor_descuento)"
                                        oninput="if(Number(this.value) > Number(10000)) this.value = Number(0); this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        v-model="empleado.factor_descuento" 
                                        :disabled="showInfonavit"
                                    />
                                </v-col>
                                <v-col v-if="empleado.tipo_factor === 'PR'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-text-field 
                                        outlined
                                        label="Porcentaje" 
                                        suffix="%"
                                        class="pa-0 ma-0" 
                                        min=0
                                        max=100
                                        v-on:keypress="presicionNumeroPorcentaje($event,empleado.factor_descuento)"
                                        oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        v-model="empleado.factor_descuento"
                                    >
                                    </v-text-field>
                                </v-col>
                            </template>


                            <template v-else>
                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <v-text-field
                                        outlined
                                        label="Número INFONAVIT"
                                        class="pa-0 ma-0"
                                        v-model="empleado.cuenta_infonavit"
                                        maxlength="10"
                                        v-mask="'##########'"
                                        disabled
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <VuetifyDatePicker
                                        outlined
                                        v-model="empleado.fecha_inicio_descuento"
                                        label="Fecha inicio descuento"
                                        placeholder="dd/mm/aaaa"
                                        ref="FechaDescuento"
                                        disabled
                                    />
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                    <v-autocomplete
                                        outlined
                                        label="Tipo descuento"
                                        :items="factores"
                                        v-model="empleado.tipo_factor"
                                        item-text="nombre"
                                        item-value="valor"
                                        persistent-hint
                                        disabled
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col v-if="empleado.tipo_factor === 'CF'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <VuetifyMoney
                                        v-model="empleado.factor_descuento"
                                        label="Cantidad a descontar"
                                        outlined 
                                        v-bind:options="options"
                                        ref="factor_descuento"
                                        disabled
                                    />
                                </v-col>
                                <v-col v-if="empleado.tipo_factor === 'FD'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-text-field
                                        outlined
                                        label="Cantidad a descontar (VSM)"
                                        min=0
                                        max=10000
                                        v-on:keypress="presicionNumero($event,empleado.factor_descuento)"
                                        oninput="if(Number(this.value) > Number(10000)) this.value = Number(0); this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        v-model="empleado.factor_descuento" 
                                        disabled
                                    />
                                </v-col>
                            </template>




                            
                            <!--<v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="empleado.fecha_alta_imss"
                                    label="Fecha alta IMSS"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaImss"
                                />
                            </v-col>-->
                            <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <v-text-field
                                    outlined
                                    label="Número FONACOT"
                                    class="pa-0 ma-0"
                                    v-model="empleado.cuenta_fonacot"
                                    id="txtFONACOT"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3" class="pt-0 pb-0 ma-0">
                                <v-text-field
                                    outlined
                                    label="Afore"
                                    class="pa-0 ma-0"
                                    v-model="empleado.afore"
                                    id="txtAFore"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0">
                                <v-divider class="mt-5 mb-10"></v-divider>
                                <h3 class="subtitle ml-2 mb-3">Cursos y capacitaciones</h3>
                            </v-col>
                            <!-- <v-divider class="mt-5 mb-10"></v-divider>
                            <h3 class="subtitle ml-2">Cursos y capacitaciones</h3> -->

                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                    <v-autocomplete
                                        outlined
                                        label="Brigadas"
                                        :items="itemsBrigadas"
                                        item-text="nombre"
                                        item-value="id"
                                        persistent-hint
                                        v-model="empleado.brigada_proteccion_civil_id"
                                        class="pa-0 ma-0"
                                    >   
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                <v-textarea 
                                    outlined
                                    label="Observaciones generales"
                                    class="pa-0 ma-0"
                                    name="input-7-4"
                                    v-model="empleado.observaciones"
                                    rows="1"
                                    id="txtObservacionesGenerales"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                        

                        <v-row class="py-0 my-0">
                            <v-col cols="12" xs="12" md="3" class="mt-0">
                                <v-autocomplete
                                    outlined
                                    label="Curso"
                                    :items="itemsCursosCapacitaciones"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="curso_id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" md="2" class="mt-0">
                                <v-text-field
                                    outlined
                                    label="Evaluación"
                                    type="number"
                                    v-mask="'###'"
                                    min="0"
                                    max="100"
                                    onkeyup="if(this.value > 100 || this.value<0) this.value = 0;"
                                    v-model="evaluacion" 
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" md="6" class="mt-0">
                                <v-text-field 
                                    outlined
                                    label="Observaciones" 
                                    v-model="observaciones"
                                ></v-text-field>
                            </v-col>
                            
                            <v-btn color="#1E2245" class="mt-4" elevation="0" dark fab  @click="agregarCurso()" small>
                                <v-icon dark>add</v-icon>
                            </v-btn>
                            
                            <template v-for="(i,index) in empleado.cursosCapacitaciones">
                                <v-col cols="12" xs="12" sm="12" md="3" :key="i.id" class="pt-0 pb-0 ma-0">
                                    <v-text-field label="Curso" outlined disabled type="text" :value="i.cursoCapacitacion.nombre"></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="2" :key="'AC'+i.id" class="pt-0 pb-0 ma-0">
                                    <v-text-field type="text" label="Evaluación" outlined :value="i.evaluacion" disabled ></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="6" :key="'BC'+i.id" class="pt-0 pb-0 ma-0">
                                    <v-text-field type="text" label="Observaciones" outlined :value="i.observaciones" disabled ></v-text-field>
                                </v-col>
                                <v-btn class="botonHover mt-1" icon fab dark small :key="'CC'+i.id" @click="eliminaCurso(index, i)" >
                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                </v-btn>
                            </template>
                        </v-row>
                    </div>
                </tab>

               <!--  <tab name="Documentos">
                    <div id="form-step-3">guardarAgregar
                        <h3 class="subtitle mt-7 ml-2 mb-6">Pendientes por solicitar</h3>

                        <template v-for="(tipoDocumento, index) in tiposDocumentos">
                            <v-row :key="index" class="pa-0 my-0">
                                <v-col cols="12" xs="12" sm="12" md="12" class="d-flex pt-0 pb-0 mb-4">
                                    <v-text-field
                                        :id="'txtFileField' + index"
                                        v-model="documentos[index].fileName"
                                        outlined
                                        class="py-1 my-0" 
                                        readonly
                                        :label="tipoDocumento.nombre"
                                        hide-details
                                    >
                                    </v-text-field>
                                    <input
                                        :id="'txtFile' + index"
                                        type="file"
                                        style="display: none"
                                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                        @change="onDocumentFilePicked($event, index)"
                                    />
                                
                                    <v-btn v-if="documentos[index].fileName == null" color="#1E2245" class="mt-2 mx-10" elevation="0" dark fab small @click="onClickInputDocument(index)">
                                        <v-icon dark>add</v-icon>
                                    </v-btn>

                                    <template v-else>
                                        <v-btn class="botonHover mt-2 ml-10" icon fab dark small @click="onClickInputDocument(index)">
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>

                                        <v-btn class="botonHover mt-2" icon fab dark small @click="onClickDeleteDocument(index)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-col>
                            </v-row>
                        </template>

                        <template v-if="action == 'update'">
                            <v-divider class="mt-5"></v-divider>
                            <h3 class="subtitle mt-8 ml-2">Cargados o almacenados</h3>

                            <v-row class="ma-0 pa-0 mb-3">
                                <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                    <table class="tbl-documentos">
                                        <thead class="tbl-header">
                                            <tr>
                                                <th style="border-top-left-radius: 20px;" class="pl-7">Documento</th>
                                                <th>Estatus</th>
                                                <th style="border-top-right-radius: 20px;">Opciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="empleado.documentos.length == 0" >
                                                <td class="emptyTableDocumentos" colspan="3">
                                                    No hay elementos para mostrar
                                                </td>
                                            </tr>
                                            <template v-else v-for="(documento, index) in empleado.documentos">
                                                <tr :key="index">
                                                    <td class="pl-7">{{ documento.descripcion }}</td>
                                                    <td><img src="/static/icon/success.png" /></td>
                                                    <td>
                                                        <v-btn class="botonHover mt-1" icon fab dark small @click="descargar(documento)">
                                                            <v-icon class="tamIconoBoton iconoDelgadoBoton">arrow_downward</v-icon>
                                                        </v-btn>
                                                        <v-btn class="botonHover mt-1" icon fab dark small @click="onClickEliminarDocumento(index, documento)">
                                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </v-col>
                            </v-row>
                        </template>
                    </div>
                </tab> -->

                <tab name="Porcentaje de pago" v-if="outsourcing">
                    <div id="form-step-4">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12">
                            <h3 class="subtitle mt-7 ml-2">Porcentaje de pago</h3>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                <v-autocomplete
                                    outlined
                                    label="Registros patronales"
                                    :items="grupo_registros_patronales"
                                    item-text="nombre"
                                    item-value="id"
                                    persistent-hint
                                    v-model="grupo_registro_patronal_id"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="5" class="pt-0 pb-0 ma-0">
                                <v-text-field 
                                    outlined
                                    label="Porcentaje" 
                                    suffix="%"
                                    class="pa-0 ma-0" 
                                    min=0
                                    max=100
                                    v-on:keypress="presicionNumeroPorcentaje($event,grupo_porcentaje)"
                                    oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    v-model="grupo_porcentaje"
                                >
                                </v-text-field>
                            </v-col>

                            <v-btn color="#1E2245" class="mt-2" elevation="0" dark fab  @click="agregarEmpresa()" small>
                                <v-icon dark>add</v-icon>
                            </v-btn>

                            <template v-for="(i,index) in empleadoEmpresas">
                                <v-col cols="12" xs="12" sm="6" md="6" :key="index" class="pt-0 pb-0 ma-0">
                                    <v-text-field id="file_input_file" label="Empresa" outlined disabled type="text" :value="i.empresa.nombre "></v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="5" :key="'A'+index" class="pt-0 pb-0 ma-0" >
                                    <v-text-field type="text" suffix="%" label="Porcentaje" outlined :value="i.porcentaje" disabled ></v-text-field>
                                </v-col>
                                <v-btn class="botonHover mt-1" :key="'B'+index" icon fab dark small @click="eliminarEmpresa(index, i)" >
                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                </v-btn>
                            </template> 
                        </v-row>
                    </div>
                </tab>
            </form-wizard>
        </ValidationObserver>


        <!-- Templete puesto, empresa, sucursal, depto, horario -->
        <template> 
            <v-row class="justify-center">
                <v-dialog v-model="dialog_agregar" persistent max-width="600px"  scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal_agregar"></v-btn>
                    </template>
                    <perfect-scrollbar style="background:#FFF">
                        <v-card elevation="0">
                            <v-card-title class="justify-center card_titulo padding_abajo">
                                <div class="headerModal">
                                    <img :src="imgModalAgregar" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal_agregar }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <div id="padre" v-show="!finaliza_carga_agregar">
                                    <div id="loading">
                                    <v-progress-circular
                                        indeterminate
                                        :size="100"
                                        :width="7"
                                        color="blue"
                                    ></v-progress-circular>
                                    </div>
                                </div>
                                <v-container v-show="finaliza_carga_agregar" class="pa-0">             
                                    <pop-puesto
                                        v-if="tPuesto && !tEmpresa && !tSucursal && !tDeptos && !tReloj"
                                        :oyente="valor"
                                        :reset="resetValuesAgregar"
                                        @save="guardarAgregar"
                                        :key="componentKey"
                                        @resetValorGuardar="resetValorGuardar"
                                    ></pop-puesto>
                                    <pop-sucursal
                                        v-if="!tPuesto && !tEmpresa && tSucursal && !tDeptos && !tReloj"
                                        :oyente="valor"
                                        :reset="resetValuesAgregar"
                                        @save="guardarAgregar"
                                        :key="componentKey"
                                        @resetValorGuardar="resetValorGuardar"
                                        @oyenteFalse="oyenteFalse"
                                    ></pop-sucursal>

                                    <pop-empresa
                                        v-if="!tPuesto && tEmpresa && !tSucursal && !tDeptos && !tReloj"
                                        :oyente="valor"
                                        :reset="resetValuesAgregar"
                                        @save="guardarAgregar"
                                        :key="componentKey"
                                        @resetValorGuardar="resetValorGuardar"
                                    ></pop-empresa>

                                    <pop-departamento
                                        v-if="!tPuesto && !tEmpresa && !tSucursal && tDeptos && !tReloj"
                                        :oyente="valor"
                                        :reset="resetValuesAgregar"
                                        @save="guardarAgregar"
                                        :key="componentKey"
                                        @resetValorGuardar="resetValorGuardar"
                                    ></pop-departamento>
                                    
                                    <pop-horario
                                        v-if="!tPuesto && !tEmpresa && !tSucursal && !tDeptos && tReloj"
                                        :oyente="valor"
                                        :reset="resetValuesAgregar"
                                        @save="guardarAgregar"
                                        :key="componentKey"
                                        @resetValorGuardar="resetValorGuardar"
                                    ></pop-horario>
                                </v-container>
                            </v-card-text>

                            <v-card-actions v-show="finaliza_carga_agregar" class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModalAgregar()"
                                    :loading="loading_guardar_agregar"
                                >Cancelar</button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="obtener()"
                                    :loading="loading_guardar_agregar"
                                >Guardar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </v-row>
        </template>
        <!-- Fin Templete puesto, empresa, sucursal, depto, horario -->
        <template>
            <v-layout row justify-center>
                <v-dialog v-model="dialogFoto" persistent max-width="450px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalFoto"></v-btn>
                    </template>
                    <v-card>
                        <div>
                            <v-btn
                                @click="cerrarModalFoto()"
                                small
                                slot="activator"
                                icon
                                text
                                class="v-btn-cerrar modal-pull-right mr-3 mt-3"
                            >
                                <v-icon class="icono-cerrar"></v-icon>
                            </v-btn>
                        </div>
                        <v-card-title>
                            <img src="/static/icon/webcam.png" class="imgFoto" />
                            <h3 class="titulo-foto">Tomar foto</h3>
                        </v-card-title>
                        <v-card-text>
                            <template style="paddin-left:30%">
                                <v-autocomplete
                                :items="devices"
                                item-text="label"
                                item-value="deviceId"
                                class="estiloFoto py-0 my-0"
                                label="Webcam"
                                persistent-hint
                                v-model="camera"
                                >
                                    <template class="pa-0 ma-0" slot="prepend-inner">
                                        <v-icon class="tamIcono invertirColor">camera_alt</v-icon>
                                        <v-icon class="fa-rotate-270 pt-3 iconoDelgado">maximize</v-icon>
                                    </template>
                                </v-autocomplete>
                            </template>

                            <template v-if="imgFoto == null">
                                <div
                                    class="center el-video"
                                    style="position: relative; height: 250px; display: flex; align-items: center; padding: 0px 65px;"
                                >
                                <web-cam
                                    id="video"
                                    ref="webcam"
                                    height="200"
                                    width="250"
                                    class="webcam"
                                    :device-id="deviceId"
                                    @started="onStarted"
                                    @stopped="onStopped"
                                    @error="onError"
                                    @cameras="onCameras"
                                    @camera-change="onCameraChange"
                                />
                                </div>
                            </template>
                            <template v-else>
                                <v-flex
                                    class="center"
                                    style="position: relative; height: 200px; display: flex; align-items: center; padding: 0px 65px;"
                                >
                                    <img :src="imgFoto" style="height :200px; width: 250px" class="webcam img-responsive"/>
                                </v-flex>

                            </template>
                            <div class="col-md-12 btn-contenedor">
                                <v-btn v-if="imgFoto == null" class="btn-capturar" @click="onCapture">Capturar</v-btn>
                                <div v-else class="col-md-12 btn-contenedor">
                                    <v-btn class="btn-capturar" @click="guardaFoto">Guardar</v-btn>
                                    <v-btn class="btn-omitir" id="no-background-hover" @click="omitirFoto()">Tomar nueva foto</v-btn>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-layout>
        </template>

        <template class="borde-card">
                <v-dialog v-model="dialogRenovar" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalTimbre"></v-btn>
                    </template>
                    <v-card class="borde-card">
                            <v-card-title class="justify-center align-center text-center card_titulo">
                                <div>
                                    <img src="/static/icon/alert_warning.png" class="imgModal pb-5" />
                                    <h2 class="titleModalTimbre pb-5">Hubo un cambio en el empleado <br>que afecta el contrato</h2>
                                    <!-- <template v-if="renovarRegenerar">
                                        <h2 class="titleModalTimbre pb-5">Hubo un cambio en el <br>contrato de este empleado</h2>
                                    </template>
                                    <template v-else>
                                        <h2 class="titleModalTimbre pb-5">Hubo un cambio en el <br>contrato de este empleado</h2>
                                    </template>  -->
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <v-container  grid-list-md id="contenedor">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 justify-center align-center text-center textTimbre">
                                        <!-- {{renovarRegenerar ? "Puede regenerar el contrato (se cancelará el contrato actual y se hará una copia del mismo con los nuevos datos) o renovar el contrato (quedará vencido en contrato y se creará uno nuevo con la información nueva).":"El contrato actual quedará vencido en contrato y se creará uno nuevo con la información nueva."}} -->
                                        Se cancelará el contrato actual y se hará una copia del mismo con los nuevos datos
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="card_accion pt-0">
                                <v-container  grid-list-md class="ma-0 pa-0">
                                    <v-col v-if="renovarRegenerar" cols="12" xs="12" sm="12" md="12" class="px-0 pb-2 pt-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="regenerarContrato()"
                                        >
                                            Regenerar
                                        </v-btn>
                                    </v-col>
                                    <!-- <v-col cols="12" xs="12" sm="12" md="12" class="px-0 py-2 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='rgb(212, 16, 16)'
                                            dark
                                            @click="renovarContrato()"
                                        >
                                            Renovar
                                        </v-btn>
                                    </v-col> -->
                                    <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pt-2 pb-0 justify-center align-center text-center">
                                        <v-btn 
                                            class="btnModalAlert"
                                            color='#828282'
                                            @click="guardarEmpleado()"
                                            text
                                        >
                                            Continuar sin regenerar
                                        </v-btn>
                                    </v-col>
                                </v-container>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

        <template class="borde-card">
            <v-dialog v-model="dialogColonia" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalColonia"></v-btn>
                </template>
                <v-card class="borde-card">
                    <ValidationObserver ref="formColonia" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">Agregar colonia</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto mt-5">
                            <div id="padre" v-show="isLoadingModalColonia">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingModalColonia" grid-list-md id="contenedor">
                                <v-row class="mt-0">
                                    
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Código postal" 
                                                class="pa-0 ma-0" 
                                                v-model="busca_cp" 
                                                :error-messages="errors" 
                                                required
                                                disabled
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Colonia" 
                                                class="pa-0 ma-0" 
                                                v-model="nombreColonia" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModalColonia()" 
                                :disabled="isSavingColonia"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardarColonia()" 
                                :disabled="invalid" 
                                :loading="isSavingColonia"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>


        <v-dialog v-model="dialogSueldo" persistent max-width="1000px" class="borde-card">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" class="botonModal" id="btnModalSueldo"></v-btn>
            </template>
            <v-card class="borde-card">
                <ValidationObserver ref="formSueldo" v-slot="{ invalid }">
                    <div xs="1" sm="1" md="1" lg="1" class="v-btn-alineacion background-transparent mt-2" style="float: right;">
                        <v-btn
                            @click="cerrarModalSueldo()"
                            small
                            icon
                            text
                            :ripple="false" 
                            class="v-btn-cerrar mr-2"
                        >
                            <v-icon class="icono-cerrar"></v-icon>
                        </v-btn>
                    </div>
                    <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                        <div class="headerModalStart">
                            <h2 class="titleModal_700_18">{{ tipoSueldo == 'sueldo' ?  'Modificar salario individual actual':'Modificar SBC individual actual'}}  </h2>
                        </div>
                    </v-card-title>
                    <v-card-text class="card_texto mt-0">
                        <div id="padre" v-show="isLoadingModalSueldo">
                            <div id="loading">
                                <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                            </div>
                        </div>
                        
                        <v-container v-show="!isLoadingModalSueldo" grid-list-md id="contenedor">
                            <v-row class="mt-0 mb-5">
                                <v-col cols=12 xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                    <v-divider class="py-0 my-6"></v-divider>
                                </v-col>
                                <v-col
                                    cols="12" xs="12" sm="12" md="12" lg="12" xl="12" 
                                    class="pa-0" 
                                >
                                    <ValidationObserver ref="formIncremento" v-slot="{ invalid }">
                                        <v-row class="pa-0 ma-0">
                                            <v-col
                                                cols="12" xs="12" sm="12" md="12" lg="8" xl="8" 
                                                class="py-0" 
                                            >
                                                <v-text-field
                                                    outlined
                                                    label="Empleado"
                                                    class="pa-0 ma-0"
                                                    disabled
                                                    v-model="nombre_completo" 
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pt-0 pb-0 ma-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    :valorDefecto="colaborador.fecha_de_aplicacion"
                                                    v-model="colaborador.fecha_de_aplicacion"
                                                    label="Fecha de aplicación"
                                                    placeholder="dd/mm/aaaa"
                                                />
                                            </v-col>

                                            <v-col v-if="tipoSueldo == 'sueldo'" cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    label="Sueldo diario"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    rules="required"
                                                    v-model="colaborador.valor"
                                                    v-bind:options="options"
                                                >
                                                    <template v-slot:append-icon >
                                                        <v-btn
                                                            class="elevation-0 ma-0 pa-0 v-btn-hover"
                                                            color="transparent"
                                                            rounded
                                                            :ripple="false" 
                                                            x-small
                                                            @click="calcularSBC()"
                                                            :loading="isLoadingSBC"
                                                        >
                                                            <v-icon color="#828282" class="tamIconoBoton" v-text="'$calculate_outline'">
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                </VuetifyMoneyValidate>
                                            </v-col>
                                            <v-col v-if="tipoSueldo == 'SBC'" cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    label="Sueldo diario"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    rules="required"
                                                    v-model="colaborador.valor"
                                                    v-bind:options="options"
                                                    readonly
                                                >
                                                    <template v-slot:append-icon >
                                                        <v-btn
                                                            class="elevation-0 ma-0 pa-0 v-btn-hover"
                                                            color="transparent"
                                                            rounded
                                                            :ripple="false" 
                                                            x-small
                                                            @click="calcularSBC()"
                                                            :loading="isLoadingSBC"
                                                        >
                                                            <v-icon color="#828282" class="tamIconoBoton" v-text="'$calculate_outline'">
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                </VuetifyMoneyValidate>
                                            </v-col>
                                            
                                            <!-- <v-col cols="12" xs="12" sm="12" md="6" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    label="Salario base cotización"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="colaborador.salario_base_cotizacion"
                                                    v-bind:options="options"
                                                />
                                            </v-col> -->
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    label="SBC fijo"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="colaborador.sbc_fijo"
                                                    v-bind:options="options"
                                                    rules="required"
                                                    @onBlur="calcularSBCblur()"
                                                >  
                                                </VuetifyMoneyValidate>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    label="SBC variable"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="colaborador.sbc_variable"
                                                    v-bind:options="options"
                                                    rules="required"
                                                    @onBlur="calcularSBCblur()"
                                                    :disabled="empleado.tipoNomina.precalculo_variables"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    label="SBC (Tope 25 UMA)"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="colaborador.salario_base_cotizacion"
                                                    v-bind:options="options"
                                                    readonly
                                                />
                                            </v-col>

                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0 d-flex justify-end">
                                                <button
                                                    v-if="guardarEdicionSueldo == 'edicion'"
                                                    class="btnCerrar"
                                                    @click="cancelarEdicion()" 
                                                    :disabled="isSavingSueldo"
                                                >
                                                    Cancelar
                                                </button>
                                                <v-btn
                                                    class="btnGuardar"
                                                    @click="guardarEdicionSueldo == 'guardar' ? guardarSueldo():guardarEdicion()" 
                                                    :disabled="invalid" 
                                                    :loading="isSavingSueldo"
                                                >
                                                    Guardar
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </ValidationObserver>
                                </v-col>

                                

                                <v-col 
                                    cols="12" xs="12" sm="12" md="12" lg="12" xl="12" 
                                    class="py-0 mt-5" 
                                    style="min-height:10rem;"
                                    
                                >
                                    <div class="headerModalStart mt-5">
                                        <h2 class="titleModal_700_18">Historial de modificaciones del salario</h2>
                                    </div>
                                    <v-col cols=12 xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
                                        <v-divider class="py-0 mt-6 mb-3"></v-divider>
                                    </v-col>
                                        
                                    <div style="overflow-x: auto;">
                                        <table class="tbl-salario" >
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th class="th-tabla pl-7 firstth">Fecha de aplicación</th>
                                                    <th class="th-tabla thTitulos px-4">Fecha de modificación</th>
                                                    <th class="th-tabla thTitulos pl-4" style="text-align: right !important;">Sueldo</th>
                                                    <th class="th-tabla thTitulos pl-4" style="text-align: right !important;">SBC fijo</th>
                                                    <th class="th-tabla thTitulos pl-4" style="text-align: right !important;">SBC variable</th>
                                                    <th class="th-tabla thTitulos pl-4" style="text-align: right !important;">SBC Total</th>
                                                    <th class="th-tabla lastth thTitulos pl-0 pr-4"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="datosTabla.length == 0" >
                                                    <td class="emptyTableComplementos" colspan="3">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else>
                                                    <template  v-for="(sueldo, index) in datosTabla" >
                                                        <tr class="trBody">
                                                            <td class="pl-8">
                                                                {{dateFormat(sueldo.fecha_de_aplicacion)}}
                                                            </td>
                                                            <td class="pl-4">
                                                                {{dateFormat(sueldo.updated_at.substr(0,10))}}
                                                            </td>
                                                            <td class="pl-4" style="text-align: right !important;">
                                                                ${{sueldo.nuevo_sueldo}}
                                                            </td>
                                                            <td class="pl-4" style="text-align: right !important;">
                                                                ${{sueldo.nuevo_sbc_fijo}}
                                                            </td>
                                                            <td class="pl-4" style="text-align: right !important;">
                                                                ${{sueldo.nuevo_sbc_variable}}
                                                            </td>
                                                            <td class="pl-4" style="text-align: right !important;">
                                                                ${{sueldo.nuevo_sbc}}
                                                            </td>
                                                            <td class="">
                                                                <div class="opcionesSalarios">
                                                                    <v-btn
                                                                        v-if="index == 0"
                                                                        class="elevation-0 ma-0 pa-0 v-btn-hover"
                                                                        color="transparent"
                                                                        @click="editarModalSueldo(sueldo)"
                                                                        rounded
                                                                        :ripple="false" 
                                                                        :min-width="0"
                                                                    >
                                                                        <v-icon color="white" class=" invertirColorBotones ma-0 pa-0 ">
                                                                            edit
                                                                        </v-icon>
                                                                    </v-btn>
                                                                    <v-btn
                                                                        class="elevation-0 ma-0 pa-0 v-btn-hover"
                                                                        color="transparent"
                                                                        @click="eliminarModalSueldo(sueldo)"
                                                                        rounded
                                                                        :ripple="false" 
                                                                        :min-width="0"
                                                                    >
                                                                        <v-icon color="white" class=" invertirColorBotones ma-0 pa-0 ">
                                                                            delete
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                                
                                                <!-- <tr v-if="datosTabla.length != 0" >
                                                    <td class="tdBlanco" colspan="3">
                                                    </td>
                                                </tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                   
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import FormWizard from '@/components/form-wizard/FormWizard.vue';
import Tab from '@/components/form-wizard/Tab.vue';
import catalogoApis from '@/api/catalogos';
import tipoDocumentoApi from '@/api/tipo_documentos';
import documentoApi from '@/api/documentos';
import clientesApi from '@/api/clientes';
import empresaApi from '@/api/empresas';
import sucursalApi from '@/api/sucursales';
import departamentoApi from '@/api/departamentos';
import horariosApi from '@/api/horarios';
import estadosCivilesApi from '@/api/estadosCiviles';
import apiPuestos from '@/api/nominas/puestos';
import Notify from '@/plugins/notify';
import apiNivelEstudios from '@/api/nivelEstudios';
import apiEmpleados from '@/api/empleados';
import bancosApis from '@/api/nominas/bancos';
import queries from '@/queries/Kardex';
import queriesC from '@/queries/Contratos';
import cognitiveServicesApi from '@/api/cognitiveServices';
import GetUrlImg from '@/plugins/Urlimagen';
import Swal from "sweetalert2";
import prestacionesApi from "@/api/nominas/prestaciones";
import usuarioApi from '@/api/usuarios';
import queriesTipoNominas from '@/queries/TipoNominas';
import PopEmpresa from '@/components/PopEmpresa.vue';
import PopPuesto from "@/components/PopPuesto.vue";
import PopSucursal from "@/components/PopSucursal.vue";
import PopDepartamento from "@/components/PopDepartamento.vue";
import PopHorario from "@/components/PopHorario.vue";
import { WebCam } from "vue-web-cam";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiTipoRegimen from "@/api/nominas/tipoRegimen";
import queriesBrigadas from '@/queries/Brigadas';
import queriesCursos from '@/queries/CursosCapacitaciones';
import apiTipoContratoSAT from '@/api/nominas/tipoContrato';
import VuetifyMoney from '@/components/VuetifyMoney.vue';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import apiTipoNomina from '@/api/nominas/tipoNomina';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import contratosApi from "@/api/contratos";
import apiTipoEmpleados from "@/api/nominas/tipoEmpleados";
import apiTipoBajaImss from "@/api/nominas/tipoBajaImss";
import apiRegimenFiscal from '@/api/nominas/regimenFiscal';
import apiIncrementoSalarial from '@/api/nominas/incrementoSalario';
import idseApi from '@/api/idse';
import apiCategoriaEmpleado from '@/api/categoriaEmpleado';
import apiCentroCosto   from '@/api/nominas/centroCostos';

export default {
    components: {
        FormWizard, 
        VuetifyDatePicker,
        Tab,
        VuetifyMoney,
        VuetifyMoneyValidate,
        "web-cam": WebCam,
        "pop-sucursal": PopSucursal,
        "pop-departamento": PopDepartamento,
        "pop-horario": PopHorario,
        "pop-puesto": PopPuesto,
        "pop-empresa": PopEmpresa,
    },
    props: ['title', 'objEmpleado', 'action', 'tabIndex'],
    data() {
        return {
            outsourcing:false,
            empleado: {
                id                      : null,
                nombre                  : '',
                apellido_paterno        :null,
                apellido_materno        :null,
                fecha_nacimiento        :null,
                sexo                    :"M",
                direccion               :null,
                estado_id               :null,
                municipio_id            :null,
                codigo_postal           :null,
                cp_id                   :null,
                colonia_id              :null,
                telefono                :"",
                correo                  :null,
                estado_civil_id         :null,
                fecha_ingreso           :null,
                fecha_baja              :'',
                dia_descanso            :"0",
                cliente_id              :null,
                empresa_id              :null,
                sucursal_id             :null,
                departamento_id         :null,
                horario_id              :null,
                tiene_hijos             :false,
                clave_interna           :null,
                email_anterior          :null,
                dias_vacaciones         :null,
                vacaciones_fiscales     :null,
                prestacion_id           :null,

                rfc                     :null,
                curp                    :null,
                tipo_contrato_id        :null,
                salario_diario          :null,
                salario_base_cotizacion :null,
                imss                    :null,
                fecha_alta_imss         :null,
                fecha_inicio_descuento  :null,
                factor_descuento        :null,
                tipo_descuento          :null,
                tipo_sangre             :null,
                fecha_planta            :null,
                cuenta_fonacot          :null,
                cuenta_infonavit        :null,
                afore                   :null,
                nombre_contacto         :null,
                telefono_contacto       :null,
                observaciones           :null,
                nivel_estudios_id       :null,
                puesto_id               :null,
                cuentas_bancarias       :[],
                foto                    :null,
                foto_default            :null,
                empleadoEmpresas        :[],
                tipo_nomina_id          :null,
                registro_patronal_id    :null,
                entidad_cfdi            :null,
                tipo_regimen_sat_id     :null,
                brigada_proteccion_civil_id :null,
                ranking:{
                    clima_laboral       : true,
                    proyectos_mejoras   : false
                },
                cursosCapacitaciones   : [],  
                cuentas_bancarias       : [],  
                nacionalidad            : null,
                lugar_nacimiento        : null,
                celular                 : null,
                observacion_salud       : null,
                conocimientos_extras    : null,
                direccion_laboral       : null,
                dias_contrato           : 0,
                tipo_pago               : null,
                codigoPostalTimbre      : {valor:null},
                documentos              :[],
                direccion_contrato      : null,
                empleado_tipo           : {id:null},
                regimen_fiscal_id       : null,
                sbc_fijo                : null,
                sbc_variable            : null,
                tipo_trabajador_imss_id : null,
                tipo_salario_imss_id    : null,
                semana_imss_id          : null,
                empleadoIMSS            : {
                    IMSS_tipo_trabajador_id: null,
                    IMSS_tipo_salario_id: null,
                    IMSS_semana_jornada_id: null
                },
                salarioMinimo           : {
                    nuevo_sbc_fijo      : null,
                    nuevo_sbc_variable  : null,
                    fecha_de_aplicacion : null,
                    nuevo_sbc           : null,
                    nuevo_sueldo        : 0,
                },
                tipoNomina: {
                    precalculo_variables:false,
                },
                fecha_cobro_finiquito: null,
                baja: false,
                segundo_contrato_id             : null,
                generarDosContratos             : false,
                empleado_categoria_id           : null,
                centroCostos                    : {id:null},
                clave_elector: null,
                otros_ingresos: 0,
                ubicacion_laboral: null
            },
            contratoEmpleado : {
                empleado_id     : null,
                tipo_contrato_id: null,
                dias_contrato   : null,
                f_imss          : null,
                f_ingreso       : null
            },
            empleadoEmpresas    :[],
            tipo_factor         : null,
            factores            :[{nombre:"Cuota Fija", valor:"CF"},{nombre:"Factor de descuento",valor:"FD"},{nombre:"Porcentaje", valor:"PR"}],
            cargaModal          : false,
            foto_default        : null,
            cuentas_eliminadas  : [],
            datosLogin          : null,
            rol                 : null,
            estado_id           : null,
            municipio_id        : null,
            cp_id               : null,
            estados             : [],
            municipios          : [],
            ciudades            : [],
            codigosPostales     : [],
            colonias            : [],
            nombreImagen        : null,
            cuenta              : null,
            clabe               : null,
            banco_id            : null,
            bancos              : [],
            estados_civiles     : [],
            clientes            : [],
            empresas            : [],
            sucursales          : [],
            departamentos       : [],
            horarios            : [],
            puestos             : [],
            nivelesEstudios     : [],
            cliente_value: null,
            empresa_value: null,
            sucursal_value: null,
            departamento_value:null,
            sucursal_id:"",
            departamento_id:"",
            calendar_cumpleanio     : false,
            calendar_ingreso        : false,
            calendar_alta_imss      : false,
            calendar_fecha_planta   : false,
            calendar_inicio_descuento : false,
            contratos               : [],
            archivoImagen           : null,
            nombreImagen            : '',
            fecha_nacimiento        : null,
            urlImg                  : GetUrlImg.getURL(),
            tiposDocumentos         : [],
            documentos              : [],
            documentos_actualizados : [],
            documentos_eliminados   : [],
            prestaciones            : [],
            busca_cp                : "",
            municipio               : "",
            estado                  : "", 
            grupo_registros_patronales          : [],
            grupo_registros_patronales2         : [],
            grupo_porcentaje        : null,
            grupo_registro_patronal_id        : null,
            empleado_empresas_eliminadas: [],
            itemsTiposNominas       : [],
            registroPatronal        : [],  
            /* variables agregar */
            loading_guardar_agregar : false,
            finaliza_carga_agregar  : false,
            dialog_agregar          : false,
            dialogRenovar           : false,
            renovarRegenerar        : false,
            titulo_modal_agregar    : null,
            imgModalAgregar         : null,
            valor                   : false,
            resetValuesAgregar      : false,
            tPuesto                 : false,
            tEmpresa                : false,
            tSucursal               : false,
            tDeptos                 : false,
            tReloj                  : false,
            mPuesto                 : false,
            mEmpresa                : false,
            mSucursal               : false,
            mDeptos                 : false,
            mReloj                  : false,
            /* fin variables agregar */
            /* Parametros para camara */
            dialogFoto              : false,
            camera                  : null,
            deviceId                : null,
            devices                 : [],
            imgFoto                 : null,
            isProcessFinished       : false,
            /* Fin parametros para camara */
            /* Parametros para subir foto */
            selectedOption          : null,
            showMenu                : false,
            archivo_adjunto         : "Subir archivo",
            nombreFoto              : null,
            nombreFotoUptade        : null,
            /* Fin parametros para subir foto */
            estadosCFDI             : [],
            tiposRegimenesSAT       : [],
            itemsBrigadas           : [],
            itemsCursosCapacitaciones   : [],
            modelCursosCapacitaciones   : [], 
            cursos_capacitaciones_eliminadas : [],
            curso_id                : null,
            evaluacion              : null,
            observaciones           : null,
            cuentasBancarias        : [],
            boolWatch               : true,
            loadingGuardar          : false,
            isDisabled              : true, //nuevo, edicion banco,
            indexBanco              : null, //nuevo, index de banco a editar
            //vuetify money configuration
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            rules: {
                requiredSueldo: value => !!value || 'El campo Sueldo es obligatorio.',
                requiredSueldoFiscal: value => !!value || 'El campo Sueldo fiscal es obligatorio.',
                min: v => v.length >= 8 || 'Min 8 caracteres',
            },
            valorCliente            : null,
            valorEmpresa            : null,
            valorSucursal           : null,
            desabilitarSueldo       : false,
            imagenModal             : '/static/icon/employees.png',
            requeridoNSS            : false,
            determinado             : false,
            obra_determinada        : false,
            componentKey            : 0,
            itemsCapacitaciones     : [],
            showInfonavit           : true,
            itemsFormaDePago        : [
                {nombre:"Transferencia bancaria", valor:"bancario"},
                {nombre:"Transferencia STP",valor:"stp"},
                {nombre:"Efectivo",valor:"efectivo"},
                {nombre:"Cheque",valor:"cheque"},
                {nombre:"Otros", valor:"otros"}
            ],
            rpVal                   : null,
            itemsTipoEmpleado       : [],
            tipos_baja_imss         : [],
            tipo_baja_imss_id       : null,
            regimenesFiscales       : [],
            mColonia                : false,
            dialogColonia           : false,
            isLoadingModalColonia   : false,
            nombreColonia           : null,
            isSavingColonia         : false,
            dialogSueldo            : false,
            isLoadingModalSueldo    : false,
            isSavingSueldo          : false,
            datosTabla              : [],
            tipoSueldo              : null,
            guardarEdicionSueldo    : null,
            nombre_completo         : '',
            colaborador             : {
                id                      : null,
                nombre_completo         : null,
                empleado_id             : null,
                salario_anterior        : null,
                sbc_anterior            : null,
                salario_nuevo           : null,
                sbc_nuevo               : null,
                fecha_de_aplicacion     : null,
                tipo                    : 'fijo',
                valor                   : null,
                salario_base_cotizacion : null,
                sbc_fijo                : null,
                sbc_variable            : null,
                nuevo_sbc_fijo          : null,
                nuevo_sbc_variable      : null,
                anterior_sbc_fijo       : null,
                anterior_sbc_variable   : null,
                fecha_de_aplicacion_anterior: null,
            },
            tipoCalculoSBC          : 'automatico',
            itemsTipoTrabajadorIMSS : [],
            itemsTipoSalarioIMSS    : [],
            itemsSemanaIMSS         : [],
            isLoadingSBC            : false,
            disableSBCF             : true,
            disableSBCV             : true,
            loadingPreSBCF          : false,
            sbc_consulta            : 0,
            activar_dos_contratos   : false,
            factorCalculo           : 0,
            itemsCategoriasEmpleado : [],
            itemsCentroCosto        : [],
            centroCostoId           : null,
            permitirGuardar       : false,
            segundo_contrato_modificado : false,
            id_anterior: null,
            id_nuevo: null,
            tipo: null,
            contrato_sat_id: null,
            tiposContratos: []
        }
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        },
        sueldoMensual: function(){
            return parseFloat(this.empleado.salarioMinimo.nuevo_sueldo) * this.factorCalculo;
        },
        
    },
    watch:{
        activar_dos_contratos: function(newData){
            if(newData == false){
                this.empleado.segundo_contrato_id = null
            }
        },
        objEmpleado: function(newData){
            /**
             * Si no llegan los datos por medio de las props entonces detenemos 
             * la consulta de la query de empleados
             */
            if(newData == null){
                this.$apollo.queries.empleado.skip = true;
            }
        },
        'empleado.cuenta_infonavit':function(newData, oldData){
            if(newData == null || newData == ''){
                this.showInfonavit = true;
                this.empleado.tipo_factor = '';
                this.empleado.fecha_inicio_descuento = '';
                this.empleado.factor_descuento = '';
                if(this.$refs.FechaDescuento != null){
                    this.$refs.FechaDescuento.resetDate();
                }
            }
            else{
                this.showInfonavit = false;
            }
        },
        'empleado.tipo_factor':function(newData, oldData) {
            if(this.boolWatch){
                if(newData != null){
                    this.empleado.factor_descuento = 0;
                }
            }
        },  
        'empleado.tipo_regimen_sat_id': function(newData, oldData) {

            if(newData != null){
                let idTipo = this.tiposRegimenesSAT.find(item => item.id === newData);
                if(idTipo == undefined) {

                }
                else{
                    if(idTipo.clave === "09"){
                        this.requeridoNSS = false;
                    }
                    else{
                        this.requeridoNSS = true;
                    }
                }
            }
            if(this.boolWatch){
                if(newData != null){
                    let idTipo = this.tiposRegimenesSAT.find(item => item.id === newData)

                    if(idTipo != undefined) {
                        if(
                            idTipo.clave === "05" ||
                            idTipo.clave === "06" ||
                            idTipo.clave === "07" ||
                            idTipo.clave === "08" ||
                            idTipo.clave === "09" ||
                            idTipo.clave === "10" ||
                            idTipo.clave === "11"
                        ){
                            this.desabilitarSueldo = true;
                            this.empleado.salarioMinimo.nuevo_sueldo = 0;
                            //this.empleado.salario_diario_fiscal = 0;
                        }
                        else{
                            this.desabilitarSueldo = false;
                        }

                        if(idTipo.clave === "09"){
                            this.requeridoNSS = false;
                        }
                        else{
                            this.requeridoNSS = true;
                        }
                    }

                }
            }
        },
        cliente_value:function(val){
            if(this.boolWatch){
                if(val != null){
                    this.mEmpresa = true;
                    this.cambiaCliente(val);
                    this.getRegistroPatronal();
                }
                else{
                    this.mEmpresa = false;
                }
            }
        },
        empresa_value:function(val, oldVal){
            if(this.boolWatch){
                if(val != null){
                    if(oldVal != null && oldVal != val){
                        this.sucursal_value = null;
                    }
                    this.mSucursal = true;
                    this.cambiaEmpresa(val);
                    this.getTiposDocumentos(val);
                    this.getTiposNominas(val);
                    this.getPrestaciones(val);
                    this.getCentroCostos(val);
                }
                else{
                    this.cambiaEmpresa(val);
                    this.mSucursal = false;
                }
            }
        },
        sucursal_value:function(val){
            if(this.boolWatch){
                if(val != null){
                    this.mDeptos = true;
                    this.mReloj =  true;
                    this.mPuesto = true;
                    this.cambiaSucursal(val);
                    this.getPuestos();
                }
                else{
                    this.cambiaSucursal(val);
                    this.mDeptos = false;
                    this.mReloj = false;
                    this.mPuesto = false;
                }
            }
        },
        clabe:function(val){
            if (val.length == 3){
                let banco = this.bancos.find(element => element.clave == val.toString());  
                if (banco != undefined){
                    this.banco_id = banco.id;
                }
                else{
                    this.banco_id=0;
                }
            }
        },
        fecha_nacimiento:function(val){
            if(this.action != "update") {
                if(!this.$session.get('empleadoBorrador')){
                    if(this.empleado.nombre != null && this.empleado.apellido_paterno != null && this.empleado.apellido_materno != null){
                        var primera_letra_apellido_P = this.empleado.apellido_paterno.trim().substring(0, 1).toUpperCase();
                        var vocal_apellido = this.empleado.apellido_paterno.trim().substring(1).toUpperCase().replace(/[^AEIOU]/g, '').substring(0, 1);
                        vocal_apellido = (vocal_apellido === '') ? 'X' : vocal_apellido;
                        var primera_letra_apellido_M = this.empleado.apellido_materno.trim().substring(0, 1).toUpperCase();
                        var primera_letra_nombre = this.empleado.nombre.trim().substring(0, 1).toUpperCase();
                        var fecha = val.split("-");
                        var rfc_curp = primera_letra_apellido_P+""+vocal_apellido+""+primera_letra_apellido_M+""+primera_letra_nombre+""+fecha[0].substring(2)+fecha[1]+fecha[2];
                        this.empleado.rfc = rfc_curp;
                        this.empleado.curp = rfc_curp;
                    }
                }
            }
        },
        busca_cp: function(val){
            if(val.length == 5){
                if(this.boolWatch){
                    this.busca_codigos();
                    this.mColonia = true;
                }
                
            }
            else{
                this.estado = "";
                this.municipio = "";
                this.colonias = [];
                this.mColonia = false;
            }
        },
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
        'empleado.tipo_contrato_id' : function(newVal, oldVal){
            // console.log(newVal)
            let contrato = this.contratos.find(element => element.id == newVal);
            let clave = "";
            if(contrato === undefined) return;
            clave = contrato.tipo_contrato? contrato.tipo_contrato.clave : contrato.clave_formato;
            if(clave == "03"){
                this.determinado = true;
                if(this.action == 'create'){
                    this.empleado.dias_contrato = 30
                }
            }else{
                this.determinado = false;
            }
            if(clave == "02"){
                this.obra_determinada = true
                if(this.action == 'create'){
                    this.empleado.dias_contrato = 0
                }
            }else{
                this.obra_determinada = false
            }

            if(this.id_anterior == null){
                if(oldVal == null){
                    oldVal = newVal
                }
                this.id_anterior = oldVal
               // console.log('hola:', this.id_anterior)
            }
            this.id_nuevo = newVal
        },
        'empleado.segundo_contrato_id': function(newVal, oldVal){
            if(newVal != null){
                if(this.action != 'add'){
                    this.segundo_contrato_modificado = true

                    if(this.id_anterior == null){
                        if(oldVal == null){
                            oldVal = newVal
                        }
                        this.id_anterior = oldVal
                        //console.log('prueba:', this.id_anterior)
                    }

                    this.id_nuevo = newVal
                    //console.log('dentro: ', this.segundo_contrato_modificado)
                }
            }
        },
        'empleado.registro_patronal_id' : function(newVal,oldVal){
            if(this.boolWatch){
                if (oldVal != newVal){
                    this.empleado.tipo_contrato_id = null;
                }
                this.getContratosFormatos();
            }
            this.getDosContratos()
            this.getFactorCalculo()
        },
        contrato_sat_id: function (val) {
            this.empleado.tipo_contrato_id = null
            this.getContratosFormatos()
        },
        'empleado.direccion':function(val){
            this.formatoDireccionContrato();
        },
        'empleado.colonia_id':function(val){
            this.formatoDireccionContrato();
        },
        'estado':function(val){
            this.formatoDireccionContrato();
        },
        'municipio':function(val){
            this.formatoDireccionContrato();
        },
        'empleado.tipo_salario_imss_id':function(val){
            if(val != null && this.action == 'create'){
                let tipoDeSalarioIMSS = this.itemsTipoSalarioIMSS.find(element => element.id == val);

                if(tipoDeSalarioIMSS.clave == '0'){
                    this.disableSBCF = false;
                    this.disableSBCV = true;
                    this.salarioMinimo.nuevo_sbc_variable = 0;
                }
                else if(tipoDeSalarioIMSS.clave == '1'){
                    this.disableSBCF = true;
                    this.disableSBCV = false;
                    this.empleado.salarioMinimo.nuevo_sbc_fijo  = 0;
                }
                else if(tipoDeSalarioIMSS.clave == '2'){
                    this.disableSBCF = false;
                    this.disableSBCV = false;
                }
                else{
                    this.disableSBCF = true;
                    this.disableSBCV = true;
                }
            }
        },
    },
    methods: {
        formatoDireccionContrato(){
            let colonia = this.colonias.find(element => element.id == this.empleado.colonia_id);
            let nombreColonia = "";
            if(colonia != undefined){
                nombreColonia = colonia.nombre!=undefined ? colonia.nombre : '';
            }
            if(this.action == 'create'){
                this.empleado.direccion_contrato = (this.empleado.direccion + " DE LA COLONIA " + nombreColonia + " CÓDIGO POSTAL " + this.busca_cp + " " + this.municipio  + ", " + this.estado).toUpperCase()
            }
        },
        scrollTop(){
            $("#container").animate({scrollTop: 0});
           /*  var elmnt = document.getElementById("container");
            elmnt.scrollTop = 0; */
        },
        presicionNumero(event, valor){
            if (/\.\d{6}/.test(valor)) 
                return event.preventDefault();
        },
        presicionNumeroPorcentaje(event, valor){
            if (/\.\d{2}/.test(valor)) 
                return event.preventDefault();
        },
        cuentaActiva(item){
            this.cuentasBancarias.forEach((element,index) => {

                if(item == index){
                    if(element.activo == false){
                        element.activo = true;
                    }
                }
                else{
                    element.activo = false;
                }
            });

        },
        async init(){
            const elementScroll = document.querySelector('#container');
            if(elementScroll) {
                const ps = new
                PerfectScrollbar(elementScroll, {
                    wheelSpeed: 2,
                    wheelPropagation: true,
                    minScrollbarLength: 20
                });
            }  
        },
        async store(valid) {
            if(valid) {
                let suma = 0;
                for(let item of this.empleadoEmpresas){
                    suma = suma + Number(item.porcentaje);
                }
                if(this.empleadoEmpresas.length > 0 && suma !== 100){
                    Notify.ErrorToast("El 100% del porcetaje de pago tiene que estar repartido entre los registros patronales agregados.");
                }else{
                    if(this.empleado.cuenta_infonavit != null && this.empleado.cuenta_infonavit != '' && this.showInfonavit == false){
                        if(this.empleado.fecha_inicio_descuento == null || this.empleado.fecha_inicio_descuento == ''){
                            Notify.ErrorToast("Favor de llenar el campo 'fecha inicio descuento' para el cálculo del INFONAVIT");
                            return;
                        }
                        /* if(this.empleado.tipo_factor == null || this.empleado.tipo_factor == ''){
                            Notify.ErrorToast("Favor de llenar el campo 'tipo de descuento' para el cálculo del INFONAVIT");
                            return;
                        }
                        if(this.empleado.factor_descuento == null || this.empleado.factor_descuento == '' || this.empleado.factor_descuento == 0){
                            Notify.ErrorToast("Favor de llenar el campo 'cantidad a descontar' con un valor mayor a cero para el cálculo del INFONAVIT");
                            return;
                        } */
                    }
                    
                    this.validaContrato();
                }
                
            } else {
                Notify.ErrorToast("Formulario incompleto...");
            }
        },

        validaContrato(){
            if(this.action == "update"){

                if(this.empleado.id == this.contratoEmpleado.empleado_id){

                   if(this.permitirGuardar){
                        this.loadingGuardar = true;
                        this.guardarEmpleado();
                   }else{
                       if(
                           this.empleado.tipo_contrato_id != this.contratoEmpleado.tipo_contrato_id ||
                           this.empleado.dias_contrato != this.contratoEmpleado.dias_contrato ||
                           this.empleado.fecha_alta_imss != this.contratoEmpleado.f_imss ||
                           this.empleado.fecha_ingreso != this.contratoEmpleado.f_ingreso ||
                           this.empleado.registro_patronal_id != this.rpVal ||
                           this.segundo_contrato_modificado == true
    
                       ){
                           if(this.segundo_contrato_modificado == true){
                                this.tipo = 2;
                           }else{
                               this.tipo = 1;
                           }
                           this.renovarRegenerar = true;
                           this.dialogRenovar = true;
                           return;
                       }
                   }



                    //if(this.empleado.tipo_contrato_id != this.contratoEmpleado.tipo_contrato_id 
                    //    || this.empleado.dias_contrato != this.contratoEmpleado.dias_contrato){
                    //    this.renovarRegenerar = true;
                    //    this.dialogRenovar = true;
                    //    return;
                    //}else if(this.empleado.registro_patronal_id != this.rpVal){
                    //    this.renovarRegenerar = true;
                    //    this.dialogRenovar = true;
                    //    return;
                    //} 
                }
            }
            
            this.loadingGuardar = true;
            this.guardarEmpleado();
        },

        async guardarEmpleado(){
                    let response_request = null;
                    this.empleado.cliente_id    = this.cliente_value;
                    this.empleado.empresa_id    = this.empresa_value;
                    this.empleado.sucursal_id   = this.sucursal_value;
                    this.isDisabled = true
                    this.empleado.rfc           = this.empleado.rfc.toUpperCase();
                    this.empleado.fecha_nacimiento = this.fecha_nacimiento;
                    let formData = new FormData();
                    formData.append('nombre', this.empleado.nombre.toString());
                    formData.append('apellido_paterno', this.empleado.apellido_paterno.toString());
                    formData.append('apellido_materno', this.empleado.apellido_materno?this.empleado.apellido_materno.toString():'');
                    formData.append('fecha_nacimiento', this.empleado.fecha_nacimiento.toString());
                    formData.append('sexo', this.empleado.sexo.toString());
                    formData.append('direccion', this.empleado.direccion.toString());

                    if(this.empleado.estado_id != "" && this.empleado.estado_id != null){
                        formData.append('estado_id',this.empleado.estado_id);
                    }
                    if(this.empleado.municipio_id != "" && this.empleado.municipio_id != null){
                        formData.append('municipio_id', this.empleado.municipio_id);
                    }
                    if(this.empleado.cp_id != "" && this.empleado.cp_id != null){
                        formData.append('cp_id',this.empleado.cp_id);
                    }
                    if(this.empleado.colonia_id != "" && this.empleado.colonia_id != null){
                        formData.append('colonia_id', this.empleado.colonia_id);
                    }
                    
                    if(this.empleado.telefono != "" && this.empleado.telefono != null){
                        formData.append('telefono', this.empleado.telefono.toString());
                    }
                    formData.append('correo', this.empleado.correo?this.empleado.correo.toString():'');
                    formData.append('fecha_ingreso', this.empleado.fecha_ingreso.toString());
                    formData.append('fecha_baja', this.empleado.fecha_baja?this.empleado.fecha_baja.toString():'');
                    if(this.empleado.baja){
                        formData.append('tipo_baja_imss_id', this.tipo_baja_imss_id?this.tipo_baja_imss_id.toString():'');
                    }
                    //formData.append('dia_descanso', this.empleado.dia_descanso);
                    if(this.empleado.horario_id != null){
                        formData.append('horario_id', this.empleado.horario_id.toString());
                    }
                    formData.append('estado_civil_id', this.empleado.estado_civil_id.toString());
                    if(this.empleado.tiene_hijos != null){
                        formData.append('tiene_hijos', this.empleado.tiene_hijos);
                    }
                    formData.append('usuario_id', this.datosLogin.id.toString());
                    formData.append('cliente_id', this.empleado.cliente_id.toString());
                    formData.append('empresa_id', this.empleado.empresa_id.toString());
                    formData.append('sucursal_id', this.empleado.sucursal_id.toString());
                    if(this.empleado.departamento_id != null) {
                        formData.append('departamento_id', this.empleado.departamento_id.toString());
                    }

                    formData.append('rfc', this.empleado.rfc.toString());
                    formData.append('curp', this.empleado.curp.toString());
                    formData.append('ubicacion_laboral', this.empleado.ubicacion_laboral)
                    formData.append('clave_elector', this.empleado.clave_elector)
                    
                    formData.append('otros_ingresos', this.empleado.otros_ingresos)
                    if(this.empleado.tipo_contrato_id != "" && this.empleado.tipo_contrato_id != null){
                        formData.append('tipo_contrato_id', this.empleado.tipo_contrato_id.toString());
                    }
                    if(this.activar_dos_contratos && this.empleado.segundo_contrato_id != "" && this.empleado.segundo_contrato_id != null){
                        //formData.append('generarDosContratos', 'true');
                        formData.append('segundo_contrato_id', this.empleado.segundo_contrato_id.toString());
                    }
                    formData.append('salario_diario', this.empleado.salarioMinimo.nuevo_sueldo.toString());
                    //formData.append('salario_diario_fiscal', this.empleado.salario_diario_fiscal.toString());
                    //formData.append('imss',this.empleado.imss.toString());
                    //formData.append('tipo_sangre', this.empleado.tipo_sangre.toString());
                    if(this.empleado.nivel_estudios_id != null){
                        formData.append('nivel_estudios_id', this.empleado.nivel_estudios_id);
                    }
                    formData.append('puesto_id', this.empleado.puesto_id);

                    if(this.empleado.prestacion_id != null) {
                        formData.append('prestacion_id', this.empleado.prestacion_id);
                    }
                    formData.append('tipo_nomina_id', this.empleado.tipo_nomina_id);
                    formData.append('registro_patronal_id', this.empleado.registro_patronal_id);
                    if(this.empleado.entidad_cfdi != null){
                        formData.append('entidad_cfdi', this.empleado.entidad_cfdi);
                    }
                    if(this.empleado.tipo_regimen_sat_id != "" && this.empleado.tipo_regimen_sat_id != null){
                        formData.append('tipo_regimen_sat_id', this.empleado.tipo_regimen_sat_id);
                    }
                    if(this.empleado.regimen_fiscal_id != "" && this.empleado.regimen_fiscal_id != null){
                        formData.append('regimen_fiscal_id', this.empleado.regimen_fiscal_id);
                    }
                    if(this.empleado.tipo_pago != null){
                        formData.append('tipo_pago', this.empleado.tipo_pago);
                    }
                    if(this.empleado.codigoPostalTimbre.valor != "" && this.empleado.codigoPostalTimbre.valor != null){
                        formData.append('codigo_postal_timbre', this.empleado.codigoPostalTimbre.valor);
                    }
                    formData.append('tipo', 'nominas');

                    if(this.empleado.direccion_contrato != "" && this.empleado.direccion_contrato != null){
                        formData.append("direccion_contrato", this.empleado.direccion_contrato);
                    }
                    if(this.empleado.empleado_tipo_id != "" && this.empleado.empleado_tipo_id != null){
                        formData.append("empleado_tipo_id", this.empleado.empleado_tipo_id);
                    }
                    if(this.empleado.dias_contrato != null){
                        formData.append('dias_contrato', this.empleado.dias_contrato.toString());
                    }
                    if(this.empleado.tipo_sangre != null){
                        formData.append('tipo_sangre', this.empleado.tipo_sangre.toString());
                    }
                    else{
                        formData.append('tipo_sangre','');
                    }
                    if(this.empleado.dias_vacaciones != null){
                        formData.append('dias_vacaciones', this.empleado.dias_vacaciones);
                    }
                    if(this.empleado.vacaciones_fiscales != null){
                        formData.append('vacaciones_fiscales', this.empleado.vacaciones_fiscales);
                    }
                    if(this.empleado.imss != null){
                        formData.append('imss', this.empleado.imss.toString());
                    }
                    if(this.empleado.ranking != null){
                        formData.append('ambiente_laboral', this.empleado.ranking.clima_laboral);
                        formData.append('mejora_continua', this.empleado.ranking.proyectos_mejoras);
                    }
                    if(this.empleado.brigada_proteccion_civil_id != null){
                        formData.append('brigada_proteccion_civil_id', this.empleado.brigada_proteccion_civil_id.toString());
                    }
                    if(this.empleado.telefono_contacto != null){
                    formData.append('telefono_contacto', this.empleado.telefono_contacto); 
                    }
                    if(this.empleado.nombre_contacto != null){
                    formData.append('nombre_contacto', this.empleado.nombre_contacto); 
                    }
                    if(this.empleado.clave_interna != null){
                    formData.append('clave_interna', this.empleado.clave_interna); 
                    }
                    if(this.empleado.fecha_alta_imss != null){
                        formData.append('fecha_alta_imss', this.empleado.fecha_alta_imss.toString());
                    }
                    if(this.empleado.fecha_planta != null){
                        formData.append('fecha_planta', this.empleado.fecha_planta.toString());
                    }
                    if(this.empleado.fecha_inicio_descuento != null){
                        formData.append('fecha_inicio_descuento', this.empleado.fecha_inicio_descuento.toString());
                    }
                    else{
                        formData.append('fecha_inicio_descuento','');
                    }
                    if(this.empleado.factor_descuento != null){
                        formData.append('factor_descuento', parseFloat(this.empleado.factor_descuento));
                    }
                    else{
                        formData.append('factor_descuento','');
                    }
                    if(this.empleado.tipo_factor != null){
                        formData.append('tipo_factor', this.empleado.tipo_factor.toString());
                    }
                    if(this.empleado.cuenta_fonacot != null){
                        formData.append('cuenta_fonacot', this.empleado.cuenta_fonacot.toString());
                    }
                    if(this.empleado.cuenta_infonavit != null){
                        formData.append('cuenta_infonavit', this.empleado.cuenta_infonavit.toString());
                    }
                    else{
                        formData.append('cuenta_infonavit','');
                    }
                    if(this.empleado.afore != null){
                        formData.append('afore', this.empleado.afore.toString());
                    }
                    if(this.empleado.observaciones != null){
                        formData.append('observaciones', this.empleado.observaciones.toString());
                    }
                    if(this.empleado.nacionalidad != null){
                        formData.append('nacionalidad', this.empleado.nacionalidad.toString());
                    }
                    if(this.empleado.lugar_nacimiento != null){
                        formData.append('lugar_nacimiento', this.empleado.lugar_nacimiento.toString());
                    }
                    if(this.empleado.celular != null){
                        formData.append('celular', this.empleado.celular.toString());
                    }
                    if(this.empleado.observacion_salud != null){
                        formData.append('observacion_salud', this.empleado.observacion_salud.toString());
                    }
                    if(this.empleado.conocimientos_extras != null){
                        formData.append('conocimientos_extras', this.empleado.conocimientos_extras.toString());
                    }
                    if(this.empleado.direccion_laboral != null){
                        formData.append('direccion_laboral', this.empleado.direccion_laboral.toString());
                    }
                    if(this.empleado.salarioMinimo.nuevo_sbc != null && this.empleado.salarioMinimo.nuevo_sbc != '' && this.empleado.salarioMinimo.nuevo_sbc != 0){
                        formData.append('salario_base_cotizacion', parseFloat(this.empleado.salarioMinimo.nuevo_sbc.toString()));
                    }
                    else{
                        formData.append('salario_base_cotizacion','');
                    }

                    if(this.empleado.salarioMinimo.nuevo_sbc_fijo != null && this.empleado.salarioMinimo.nuevo_sbc_fijo != '' && this.empleado.salarioMinimo.nuevo_sbc_fijo != 0){
                        formData.append('sbc_fijo', parseFloat(this.empleado.salarioMinimo.nuevo_sbc_fijo.toString()));
                    }
                    else{
                        formData.append('sbc_fijo','');
                    }

                    if(this.empleado.salarioMinimo.nuevo_sbc_variable != null && this.empleado.salarioMinimo.nuevo_sbc_variable != '' && this.empleado.salarioMinimo.nuevo_sbc_variable != 0){
                        formData.append('sbc_variable', parseFloat(this.empleado.salarioMinimo.nuevo_sbc_variable.toString()));
                    }
                    else{
                        formData.append('sbc_variable','');
                    }

                    if(this.empleado.tipo_trabajador_imss_id != null && this.empleado.tipo_trabajador_imss_id != '' && this.empleado.tipo_trabajador_imss_id != 0){
                        formData.append('tipo_trabajador_imss_id', parseFloat(this.empleado.tipo_trabajador_imss_id.toString()));
                    }
                    else{
                        formData.append('tipo_trabajador_imss_id','');
                    }

                    if(this.empleado.empleado_categoria_id != null && this.empleado.empleado_categoria_id != '' && this.empleado.empleado_categoria_id != 0){
                        formData.append('empleado_categoria_id', parseFloat(this.empleado.empleado_categoria_id.toString()));
                    }
                    else{
                        formData.append('empleado_categoria_id','');
                    }
                    if(this.centroCostoId != null && this.centroCostoId != '' && this.centroCostoId != 0){
                        formData.append('centro_costo_id', parseFloat(this.centroCostoId.toString()));
                    }
                    else{
                        formData.append('centro_costo_id','');
                    }

                    if(this.empleado.tipo_salario_imss_id != null && this.empleado.tipo_salario_imss_id != '' && this.empleado.tipo_salario_imss_id != 0){
                        formData.append('tipo_salario_imss_id', parseFloat(this.empleado.tipo_salario_imss_id.toString()));
                    }
                    else{
                        formData.append('tipo_salario_imss_id','');
                    }

                    if(this.empleado.semana_imss_id != null && this.empleado.semana_imss_id != '' && this.empleado.semana_imss_id != 0){
                        formData.append('semana_imss_id', parseFloat(this.empleado.semana_imss_id.toString()));
                    }
                    else{
                        formData.append('semana_imss_id','');
                    }


                    let existeFoto = false;
                    if(this.archivoImagen != null){
                        formData.append('archivo', this.archivoImagen);
                        existeFoto = true
                    }

                    var cuentas_ban = JSON.stringify(this.cuentasBancarias);
                    var cuentas_eli = JSON.stringify(this.cuentas_eliminadas);
                    formData.append('cuentas_bancarias', cuentas_ban);
                    formData.append('cuentas_eliminadas', cuentas_eli);
                    //this.$session.remove("cuentas_eliminadas");

                    var emp_empresas = JSON.stringify(this.empleadoEmpresas);
                    formData.append('empleado_empresas', emp_empresas);

                    var cursos_cap = JSON.stringify(this.empleado.cursosCapacitaciones);
                    formData.append('cursos_capacitaciones', cursos_cap);
                    var cursos_eli = JSON.stringify(this.cursos_capacitaciones_eliminadas);
                    formData.append('cursos_capacitaciones_eliminadas', cursos_eli);

                    if(this.action != "update") {
                        formData.append('tipo', 'nominas');
                        await apiEmpleados.addEmpleado(formData)
                        .then((response) => {
                            response_request    = response;
                            let documentos      = this.documentos.filter(item => item.fileName != null);

                            if(documentos.length >= 1) {
                                this.storeDocuments(documentos, response.id);
                            } else {
                                Notify.Success("Operación exitosa", "El empleado se guardó satisfactoriamente.");
                                this.loadingGuardar = false;
                                this.permitirGuardar = false;
                                this.$emit("cerrarModal", "guardarCerrar");
                                this.$modal.hide('kardexModal');
                            }
                        })
                        .catch(err => {
                            this.loadingGuardar = false;
                            this.permitirGuardar = false;
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }

                                Notify.ErrorToast(leyenda);
                            }
                            return false;
                        });
                    } else {
                        let anterior = this.$session.get("empleado_update");
                        formData.append('id', anterior.id);
                        formData.append("email_anterior", anterior.correo);
                        let updateFoto = false;
                        updateFoto = this.empleado.foto_default;
                        formData.append('update_foto', updateFoto); 
                        var empresas_eli = JSON.stringify(this.empleado_empresas_eliminadas);
                        formData.append('empleado_empresas_eliminadas', empresas_eli);

                        var doc_eli = JSON.stringify(this.documentos_eliminados);
                        formData.append('documentos_eliminados', doc_eli);

                        await apiEmpleados.updateEmpleado(formData)
                        .then((response) => {
                            response_request = response;

                            let documentos      = this.documentos.filter(item => item.fileName != null);

                            if(documentos.length >= 1) {
                                this.storeDocuments(documentos, response.empleado.id);
                            } else {
                                Notify.Success("Operación exitosa", "El empleado se actualizó satisfactoriamente.");
                                this.loadingGuardar = false;
                                this.permitirGuardar = false;
                                this.$emit("cerrarModal", "guardarCerrar");
                                this.$modal.hide('kardexModal');
                            }
                        })
                        .catch(err => {
                            this.loadingGuardar = false;
                            this.permitirGuardar = false;
                            if(this.dialogRenovar == true){
                                this.dialogRenovar = false
                            }
                            if(typeof err.response.data.error === 'string'){
                                Notify.ErrorToast(err.response.data.error);
                            }else{
                                let error = Object.values(err.response.data.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }

                                Notify.ErrorToast(leyenda);
                            }
                            return false;
                        });
                    }

                if(response_request != null && existeFoto == true) {
                    let resultFaceList = await this.getFaceList(response_request);
                    await this.faceListUpload(response_request, resultFaceList);
                    //return true;
                }

                if(response_request != null) {
                    return true; //para contratos - cambio de contratos
                }

        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el API renovarContrato() enviando los 
                     parametros tomados del contrato seleccionado
                     y actualiza la tabla para mostrar los cambios.
        */
        async renovarContrato(){
            this.dialogRenovar = false;

            let params = {"empleado_id": this.empleado.id,
                          "tipo_contrato_id": this.empleado.tipo_contrato_id,
                          "dias": this.empleado.dias_contrato};
            
            this.loadingGuardar = true;
            if(await this.guardarEmpleado()){
                await contratosApi
                    .renovarContrato(params)
                    .then((response) => {
                        
                    })
                    .catch((err) => {
                        this.loadingGuardar = false;
                        this.respuesta_error(err);
                    });
            }
        },

        async regenerarContrato(){
            console.log('click')
            this.dialogRenovar = false;
            let params = {
                "empleado_id": this.empleado.id,
                "anterior_id": this.id_anterior,
                "nuevo_id": this.id_nuevo,
                "tipo": this.tipo,
            };

            console.log(params);    
            this.loadingGuardar = true;
            if(await this.guardarEmpleado()){
                contratosApi
                    .regenerarContrato(params)
                    .then((response) => {
                        console.log('regenerado')
                    })
                    .catch((err) => {
                        this.loadingGuardar = false;
                        this.respuesta_error(err);
                    });
            }
            
            

           
        },

        async storeDocuments(newArray, empleadoId) {
            await newArray.forEach(item => {
                let formData = new FormData();
                formData.append('archivo', item.file);
                formData.append('cliente_id', this.empleado.cliente_id);
                formData.append('empresa_id', this.empleado.empresa_id);
                formData.append('sucursal_id', this.empleado.sucursal_id);
                formData.append('departamento_id', this.empleado.departamento_id);
                formData.append('empleado_id', empleadoId);

                documentoApi.addArchivo(formData)
                    .then((response) => {
                        if(response.success) {
                            let documentoParams = {
                                tipo_id     : item.id,
                                empleado_id : empleadoId,
                                nombre      : response.nombre,
                                descripcion : item.nombre,
                                extension   : response.extension,
                                url         : response.url
                            };

                            documentoApi.addDocumento(documentoParams).then((response) =>{
                                if(this.action == "add"){
                                    Notify.Success("Operación exitosa", "El empleado se guardó satisfactoriamente.");
                                }
                                else{
                                    Notify.Success("Operación exitosa", "El empleado se actualizó satisfactoriamente.");
                                }
                                this.$modal.hide('kardexModal');
                                this.loadingGuardar = false;
                                this.$emit("cerrarModal", "guardarCerrar");
                            })
                            .catch(err =>{
                                this.loadingGuardar = false;
                                Notify.WarningToast(`El documento ${item.nombre} se cargó correctamente, pero hubo un problema al registrarlo en el sistema.`)
                            });
                        } else {
                            this.loadingGuardar = false;
                            Notify.ErrorToast(`El documento ${item.nombre} no se subió correctamente.`);
                        }
                    })
                    .catch(err => Notify.ErrorToast("Ocurrió un error al intentar cargar el documento."));
            });
        },
        storeDraft() {
            if(this.empleado.rfc!=null){
                this.empleado.rfc = this.empleado.rfc.toUpperCase();
            }
            
            this.empleado.estado_id     = this.estado;
            this.empleado.municipio_id  = this.municipio;
            //this.empleado.cp_id         = this.busca_cp;
            this.empleado.cliente_id    = this.cliente_value;
            this.empleado.empresa_id    = this.empresa_value;
            this.empleado.sucursal_id   = this.sucursal_value;
            
            this.$session.remove("empleadoBorrador");
            this.$session.set('empleadoBorrador', this.empleado);

            this.$session.remove("kardexCP");
            this.$session.set('kardexCP', this.busca_cp);

            Notify.Success("Operación exitosa!", "El empleado se guardó en sesión correctamente");
            this.$emit("cerrarModal", "cancelar");
            this.$modal.hide('kardexModal');
        },
        cancel() {
            this.$session.remove("cuentas_eliminadas");
            this.$session.remove("email_anterior");
            this.$session.remove("kardexCP");
            this.$modal.hide('kardexModal');
            this.$emit("cerrarModal", "cancelar");
            this.$session.remove("empleadoBorrador");
            this.isDisabled = true
            this.permitirGuardar = false
        },
        async getParam(){
            let self            = this;
            let param = { activo:true, paginate:false, order:"asc"}
            await bancosApis.find(param).then((response) => {
                self.bancos = response.data;
            })
            .catch(err => {
                console.error(err);
                this.loading_guardar = false;
                Notify.ErrorToast("Ocurrió un error al cargar los bancos.")
            });
        },
        async getEstadosCiviles(estado_civil = null){
            let param = {activo: true, paginate: false};
            await estadosCivilesApi.getEstadosCiviles(param).then((response) =>{
                this.estados_civiles = response.data;
                if(estado_civil != null){
                this.empleado.estado_civil_id = estado_civil;
                }
            })
            .catch(err =>{
                Notify.ErrorToast("Ocurrió un error al cargar los estados civiles.");
            })
        },
        async getClientes(clientes = null){
            let param = { activo:true, paginate:false};
            await clientesApi.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch(err =>{
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.")
            })
        },
        async getEmpresas(){
            let parametros = { activo:true, paginate:false };
            await empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas=response.data;
            })
            .catch(err => {
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.")
            })
        },
        cambiaCliente(cliente, accion = null){
            if (this.empleado.cliente_id != cliente) {
                this.empleado.empresa_id = null;
                this.empleado.registro_patronal_id = null;
            }
            if(cliente == null){
                return;
            }
            let param = {activo: true, paginate: false, cliente_id: cliente != null?cliente:this.datosLogin.cliente_id, solo_pagos:false};
            empresaApi.getEmpresas(param).then((response) => {
                this.empresas = response.data;
                //this.empresa_value = null;
            })
            .catch(err => {        
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.")
            });
        },
        cambiaEmpresa(empresa, accion = null){
            if (this.empleado.empresa_id != empresa) {
                this.empleado.sucursal_id = null;
                this.empleado.prestacion_id = null;
                this.empleado.tipo_nomina_id =  null;
            }
            
            let parametros = {activo:true, paginate:false, empresa_id:empresa != null?empresa:this.datosLogin.empresa_id};
            sucursalApi.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
                //this.sucursal_value = null;
            })
            .catch(err =>{    
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.")
            })
        }, 
        cambiaSucursal(sucursal, accion = null){
            if (this.empleado.sucursal_id != sucursal) {
                
                this.empleado.puesto_id = null;
            }
            if(sucursal == null){
                this.departamentos = [];
                this.empleado.departamento_id = null;
                return;
            }
            let parametros = {activo:true, paginate:false, sucursal_id:sucursal};
            departamentoApi.getDepartamentos(parametros).then((response) => {
                this.departamentos = response.data;
                //this.empleado.departamento_id = null;
            })
            .catch(err => {
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.")
            });

            let parametrosHorario = {activo:true, paginate:false, sucursal_id:sucursal};
                horariosApi.getHorarios(parametrosHorario).then((response) => {
                    this.horarios = response.data;
                    })
                    .catch(err => {
                    Notify.ErrorToast("Ocurrió un error al cargar los horarios.")
                });
        },
        cambiaDepartamento(departamento, accion = null){
            let parametros = {activo:true, paginate:false, departamento_id:departamento};
            horariosApi.getHorarios(parametros).then((response) => {
                this.horarios = response.data;
            })
            .catch(err => {
                Notify.ErrorToast("Ocurrió un error al cargar los horarios.")
            });
        },
        async getTiposDocumentos(empresaId) {
            let params = {
                activo      : true,
                paginate    : false,
                empresa_id  : empresaId
            };

            await tipoDocumentoApi.getDocumentos(params)
                .then(response => {
                    this.tiposDocumentos    = response.data;
                    let newArray            = [];

                    if(this.action == "update" && this.empleado.documentos.length > 0) {
                        this.empleado.documentos.forEach(element => {
                            /* let arrNew = this.tiposDocumentos.map(item => item.id);
                            console.log(arrNew);

                            console.log("ID: " + element.tipo.id)
                            let index = arrNew.indexOf(element.tipo.id)
                            console.log(element.descripcion + " - " + index);
                            if(index !== -1) {
                                this.tiposDocumentos.splice(index, 1);
                            } */
                            this.tiposDocumentos = this.tiposDocumentos.filter(item => item.id != element.tipo.id);
                        });
                    }

                    this.tiposDocumentos.forEach(element => {
                        let item = {
                            id      : element.id,
                            nombre  : element.nombre,
                            fileName: null,
                            file    : null
                        };

                        this.documentos.push(item);
                    });
                })
                .catch(error => console.error(error));
        },
        async getPuestos(){
            this.puestos = []
            let param = { activo:true, paginate:false, cliente_id: this.cliente_value, empresa_id: this.empresa_value, sucursal_id: this.sucursal_value };
            await apiPuestos.find(param).then((response) => {
                //this.puestos = response.data;
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.claveNombre = item.clave + ' - ' +  item.nombre;
                        this.puestos.push(item);
                    })
                }
            })
            .catch(err => {
                Notify.ErrorToast("Ocurrió un error al cargar los puestos.");
            });
        },
        async getNivelEstudios(){
            let param = { activo:true, paginate:false };
            await apiNivelEstudios.find(param).then((response) => {
                this.nivelesEstudios =  response.data;
            })
            .catch(err => {
                Notify.ErrorToast("Ocurrió un error al cargar los niveles de estudio.");
            })
        },
        agregarCuenta(){
            if( this.cuenta   == null || this.cuenta   == "" ||
                this.banco_id == null || this.banco_id == "" ||
                this.clabe    == null|| this.clabe     == "" ){
                Notify.ErrorToast("Favor de agregar todos los campos.");
            }
            else if(this.cuentasBancarias.length >= 3){
                Notify.ErrorToast("Solo se puede ingresar tres cuentas bancarias.");
            }
            else if(this.cuenta.length < 10 || this.cuenta.length > 24){
                Notify.ErrorToast("Solo se puede ingresar cuentas bancarias entre 10 y 24 digitos.");
            }
            else if(this.clabe.length != 18){
                Notify.ErrorToast("La clabe interbancaria tiene que ser de  18 digitos.");
            }
            else{
                var actual = true;
                var repetida = false;
                var cuentas = this.cuentasBancarias;
                         
                for (let i = 0; i < cuentas.length; i++) {
                    let temp_banco = cuentas[i].banco_id;
                    
                    if(cuentas[i].banco_id == undefined)
                        temp_banco = cuentas[i].banco.id;

                    if(this.banco_id == temp_banco && this.cuenta == cuentas[i].cuenta_bancaria && this.clabe == cuentas[i].clabe_bancaria ){
                        repetida = true;
                    }
                }

                if(repetida ){
                    Notify.ErrorToast("No se puede agregar la misma cuenta dos veces.");
                }
                else{
                    if(this.cuentasBancarias.length > 0){
                        actual = false;
                    }
                    const banco = this.bancos.find(element => element.id == this.banco_id);
                    let cuentas = {id:0,banco_id:this.banco_id, cuenta_bancaria: this.cuenta, clabe_bancaria: this.clabe, activo: actual,
                        banco:{nombre: banco.nombre}}
                    this.cuentasBancarias.push(cuentas);
                    //this.cuentasBancarias.push(cuentas);
                    this.banco_id = null;
                    this.cuenta = null;
                    this.clabe = null;
                }
            }
            
        },
        eliminaCuenta(index,item){
            let id_cuenta = {"id":item.id};
            
            /*if (this.$session.get("cuentas_eliminadas") == undefined) {
                this.$session.set('cuentas_eliminadas',[]);
            }*/

            //let cuentas_eliminadas = this.$session.get("cuentas_eliminadas");
            this.cuentas_eliminadas.push(id_cuenta);
            //this.$session.set('cuentas_eliminadas',cuentas_eliminadas);
            this.cuentasBancarias.splice(index,1);
            if(this.cuentasBancarias.length>=1 && item.activo == true){
                this.cuentasBancarias[0].activo = true;
            }
        },

        editaCuenta(index,i){
            //console.log(i)
            this.indexBanco = index
            this.isDisabled = false
        },
        
        onClickInputDocument(index) {
            document.getElementById("txtFile" + index).click();
        },
        onClickDeleteDocument(index, type = null) {
            if(type == null) {
                let item        = this.documentos[index];
                item.fileName   = null;
                item.file       = null;
                this.documentos.splice(index, 1, item);
            } else {
                let item = {
                    id : this.empleado.documentos[index].id
                };

                this.documentos_eliminados.push(item);
                this.empleado.documentos.splice(index, 1);
            }
        },
        onClickEliminarDocumento(index, documento){
            let self = this;
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el documento?",
                "Eliminar",
                () => {
                    self.loading = true;
                    documentoApi.deleteDocumento(documento.id).then((response) => {
                        Notify.Success("Operación exitosa", "El documento fue eliminado satisfactoriamente.");
                        this.empleado.documentos.splice(index, 1);
                        this.getTiposDocumentos(this.empresa_value);
                    })
                    .catch((err) => {
                        console.log(err);
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            );
        },
        async descargar(item = null) {
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "incidencias/download";
            var nombre_archivo = item.url.split("/");
            nombre_archivo = item.nombre + "_" + nombre_archivo[3];
            let parametros = { url_archivo: item.url };
            try {
                axios({
                method: "post",
                url: url,
                responseType: "blob",
                data: parametros,
                }).then(function(response) {
                FileDownload(response.data, nombre_archivo);
                });
            } catch (error) {
                console.log(error);
            }
        },
        onDocumentFilePicked(event, index) {
            const file = event.target.files
            if(file[0] !== undefined) {
                let fileName = file[0].name
                if(fileName.lastIndexOf('.') <= 0) return;

                const archivo = new FileReader();
                archivo.readAsDataURL(file[0])
                archivo.addEventListener('load', () => {
                    let item        = this.documentos[index];
                    item.fileName   = file[0].name;
                    item.file       = file[0];
                    this.documentos.splice(index, 1, item);
                });
            }
        },
        cerrarModalRenovar(){
            this.permitirGuardar = true
            this.dialogRenovar = false;

        },
        cerrarModal(){
            this.showMenu = false;
            this.archivoImagen = null;
            this.nombreImagen = "";
            this.archivo_adjunto = 'Subir archivo';
            $("#avatar").val("");
            $("#texto").text("");
            $("#img").attr(
                "src",
                "/static/avatar/usuario4.jpg"
            );
        },
        omitirFoto() {
            this.imgFoto = null;
            setTimeout(() => {
                this.$refs.webcam.start();
                }, 550);
        },
        cerrarModalFoto(){
            this.dialogFoto = false;
            this.archivo_adjunto = 'Subir archivo';
            setTimeout(() => {
                this.$refs.webcam.stop();
            }, 550);
        },
        pickFile() {
            this.$refs.image.click();
        },
        onFilePicked(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.nombre_archivo = files[0].name;
                if (this.nombre_archivo.lastIndexOf(".") <= 0) {
                    return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                    this.archivo.archivo = files[0];
                });
            } else {
                this.nombre_archivo = "";
                this.imageUrl = "";
            }
        },
        almacenarImagen() {
            this.$refs.imagen.click();
            this.showMenu = false;
            this.validarImagen();
        },
        validarFormatoFecha(campo) {
            var RegExPattern = /^\d{2,4}\-\d{1,2}\-\d{1,2}$/;
            if (
                campo.match(RegExPattern) &&
                campo != "" &&
                campo != null &&
                campo != undefined
            ) {
                return true;
            } else {
                return false;
            }
        },
        existeFecha(fecha) {
            var fechaf = fecha.split("-");
            var day = fechaf[2];
            var month = fechaf[1];
            var year = fechaf[0];
            var date = new Date(year, month, "0");
            if (day - 0 > date.getDate() - 0) {
                return false;
            }
            return true;
        },
        onFilePickedImagen(evento){
            const img = evento.target.files
            if(img[0] !== undefined){
                this.nombreImagen = img[0].name
                if(this.nombreImagen.lastIndexOf('.') <= 0){
                return
                }
                const archivo = new FileReader()
                archivo.readAsDataURL(img[0])
                archivo.addEventListener('load', () => {
                this.archivoImagen = img[0];
                })
            } else{
                this.nombreImagen = ''
                this.archivoImagen = null;
            }
        },
        validarImagen(){
            var self = this;
            //Cuando cambie #avatar
            $("#avatar").change(function(){
                $('#texto').text('');
                $('#img').attr('src', '');
                if(self.validarExtension(this)){
                    if(self.validarPeso(this)){
                        self.verImagen(this);
                    }
                }
            });
        },
        validarExtension(datos){
            //Validacion de estensiones permitidas
            var extensionesValidas = ".png, .gif, .jpeg, .jpg";
            var ruta = datos.value;
            var extension =  ruta.substring(ruta.lastIndexOf('.')+1).toLowerCase();
            var extensionValida = extensionesValidas.indexOf(extension);
            if(extensionValida < 0){
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "Error",
                    html: 'La extensión no es válida <br> Su fichero tiene de extensión: '+extension,
                    showConfirmButton: true,
                    showClass: {
                        popup: 'animated fadeInDown faster'
                    },
                    hideClass: {
                        popup: 'animated fadeOutUp faster'
                    }
                }).then((result) => {
                    if (result.value) {
                        $("#avatar").val('');
                        $("#form").val('');
                        this.nombreImagen='';
                        this.archivoImagen = null;
                    }
                })
                return false;
            } else {
                return true;
            }
        },
        validarPeso(datos){
            var pesoPermitido = 1536;
            if(datos.files && datos.files[0]){
                var pesoFichero = datos.files[0].size/1024;
                if(pesoFichero > pesoPermitido){
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: "Error",
                    html: 'El tamaño de la imagen supera el limite permitido!! <br> El peso maximo permitido del fichero es: ' + pesoPermitido + ' KB Su fichero tiene: ' + pesoFichero + ' KBs',
                    showConfirmButton: true,
                    showClass: {
                        popup: 'animated fadeInDown faster'
                    },
                    hideClass: {
                        popup: 'animated fadeOutUp faster'
                    }
                }).then((result) => {
                    if (result.value) {
                        $("#avatar").val('');
                        $("#form").val('');
                        this.nombreImagen='';
                        this.archivoImagen = null;
                    }
                })
                    return false;
                }else{
                    return true;
                }
            }
        },
        verImagen(datos){
            if(datos.files && datos.files[0]){
                var reader =  new FileReader();
                reader.onload = function(e){
                $('#img').attr('src',e.target.result);
                };
                reader.readAsDataURL(datos.files[0]);
            }
        },
        toggleDropdown (event) {
            event.currentTarget.classList.toggle('is-active')
        },
        toggleMenu() {
            this.showMenu = true;
        },
        abrirModalFoto(capturar, item = null) {
            setTimeout(() => {
                this.$refs.webcam.start();
            }, 750);
            this.showMenu = false;
            if (capturar == "capturar") {
                document.getElementById("btnModalFoto").click();
            }
            this.imgFoto = null;
        },
        onCapture() {
            this.imgFoto = this.$refs.webcam.capture();
        },
        b64toBlob(base64URL, sliceSize) {
            var arr = base64URL.split(",");
            var contentType = arr[0].match(/:(.*?);/)[1];
            var b64Data = arr[1];
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (
                var offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        },
        guardaFoto(){
            this.$refs.imagen = this.imgFoto;
            $("#img").attr(
                "src",
                this.imgFoto
            );
            this.archivo_adjunto = 'Foto1.jpg';
            this.empleado.foto = this.imgFoto; //aqui meto la fotos capturada
            this.imgFoto = this.b64toBlob(this.imgFoto);
            const archivo = new FileReader();
                archivo.readAsDataURL(this.imgFoto);
                archivo.addEventListener("load", () => {
                    this.archivoImagen = this.imgFoto;
                });
            this.dialogFoto = false;
            console.log(this.archivoImagen);
        },
        onStarted(stream) {
            console.log("On Started Event", stream);
        },
        onStopped(stream) {
            console.log("On Stopped Event", stream);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log("On Error Event", error);
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
        },
        async getFaceList(data) {
            let isCreated = false;
            let responseData;

            await cognitiveServicesApi.getFaceList(data.empleado.empresa_id).then(async response => {
                responseData = response;
                isCreated = true;
            })
            .catch(error => {
                return false;
            });

            if(isCreated) {
                let listFaces = []
                
                await cognitiveServicesApi.getFaces(responseData.largeFaceListId).then(response => {
                    listFaces = response
                })
                .catch(error => console.error("ERROR", error));
                
                listFaces.forEach(item => {
                    if(item.userData == data.empleado.id){
                        cognitiveServicesApi.deleteFace(responseData.largeFaceListId, item.persistedFaceId)
                    }
                })   
            }

            return isCreated;
        },
        async faceListUpload(data, isCreated = false) {
            if (!isCreated) {
                let listParams = {
                    name: data.empleado.empresa_id,
                    userData: `Empresa-${data.empleado.empresa_id}`
                };

                await cognitiveServicesApi.createFaceList(data.empleado.empresa_id, listParams).then(response => {
                    console.log("FACELIST", response);
                })
                .catch(error => console.log(error));
            }

            if (this.archivoImagen != null) {
                await cognitiveServicesApi.addFace(
                    data.empleado.empresa_id,
                    { url: this.urlImg + data.empleado.foto },
                    data.empleado.id
                )
                .then(response => console.log("ADDFACE", response))
                .catch(error => console.log(error));

                await cognitiveServicesApi
                .train(data.empleado.empresa_id)
                .then(response => console.log("TRAIN EXITOSO"))
                .catch(error => console.log(error));
            }
        },

        primerConsonante(str) {
            str = str.trim().substring(1).replace(/[AEIOU]/ig, '').substring(0, 1);
            return (str === '') ? 'X' : str;
        },
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },
        async busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            await catalogoApis.getCP(params).then((response) => {
                let result = response.data;

                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado     = "";
                    this.municipio  = "";
                    this.colonias   = [];
                    this.mColonia   = false;
                    return;
                }
                this.estado                 = result[0].nomb_estado;
                this.municipio              = result[0].nomb_mun;
                this.empleado.estado_id     = result[0].estado_id;
                this.empleado.municipio_id  = result[0].municipio_id;
                this.empleado.cp_id         = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate:false }).then((response) => {
                    self.colonias = response.data;
                    if(self.action == "update"){
                        this.cargaModal = true;
                    }
                })
                .catch((e) => {
                    Notify.ErrorToast(
                        "Se presento un problema al cargar las colonias."
                    );
                return;
                });
            })
            .catch((e) => {
                console.log(e);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar los estados y ciudades."
                );
            });
        },
        async getEstadosCFDI() {
            let param = { order: "asc", paginate:false }
            await catalogoApis.getEstados(param).then((response) => {
                this.estadosCFDI = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los estados.");
            });
        },
        async getPrestaciones(emp_id = null){
            let params = {
                activo: true,
                paginate: false,
                empresa_id: emp_id
            };
            await prestacionesApi.find(params).then((response) => {
                this.prestaciones = response.data;
            })
            .catch((err)=>{
                Notify.ErrorToast(
                    "Ocurrió un error al cargar las prestaciones"
                );
            })
        },
        async getEmpresasGrupo(){
            /* let param = {activo: true, paginate: false, cliente_id: this.cliente_value};
            await apiRegistroPatronal.find(param).then((response) => {
                this.grupo_registros_patronales = this.grupo_registros_patronales2 = response.data;
            })
            .catch(err => {        
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.")
            }); */

            if(this.rol == 'root'){
                let param = {activo: true, paginate: false, cliente_id: this.cliente_value};
                await apiRegistroPatronal.find(param).then((response) => {
                    this.grupo_registros_patronales  = response.data;
                    this.grupo_registros_patronales2 = JSON.parse(JSON.stringify(response.data));
                })
                .catch(err => {        
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.")
                });
            }
            else{
                await usuarioApi.usuarioEmpresas(this.datosLogin.id).then((response)=>{
                    this.grupo_registros_patronales  = response; 
                    this.grupo_registros_patronales2 = JSON.parse(JSON.stringify(response));
                })
                .catch(error => {
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
            }            
        },
        async getFactorCalculo(){
            if(this.empleado.registro_patronal_id == null || this.empleado.registro_patronal_id == ''){
                return;
            }
            await apiRegistroPatronal.factorCalculo(this.empleado.registro_patronal_id).then((response) => {
                this.factorCalculo = response.valor;
            })
            .catch(err => {      
                console.log("Error factor cálculo");
            });
        },
        agregarEmpresa(){
            if(this.grupo_porcentaje <= 0){
                Notify.ErrorToast("El porcentaje tiene que ser mayor al 0%");
                return;
            }
            if( this.grupo_registro_patronal_id   == null || this.grupo_registro_patronal_id   == "" ||
                this.grupo_porcentaje == null || this.grupo_porcentaje == "" ){
                Notify.ErrorToast("Favor de agregar todos los campos.");
            }
            else{
                if(this.empleadoEmpresas.length > 0){
                    let suma = 0;
                    this.empleadoEmpresas.forEach(element => {
                        suma += parseInt(element['porcentaje'], 10);
                    });
                    suma += parseInt(this.grupo_porcentaje, 10);
                    if(suma > 100){
                        Notify.ErrorToast("Se supera el 100% del pago de empresas.");
                    }
                    else{
                        const emp = this.grupo_registros_patronales.find(element => element.id == this.grupo_registro_patronal_id);
                        let grp = {id:0,empresa_id:this.grupo_registro_patronal_id, porcentaje: this.grupo_porcentaje, 
                            empresa:{nombre: emp.nombre}}
                        this.empleadoEmpresas.push(grp);


                        let index = this.grupo_registros_patronales.indexOf(emp);
                        this.grupo_registros_patronales.splice(index, 1);


                        this.grupo_registro_patronal_id = null;
                        this.grupo_porcentaje = null;
                    }
                }
                else{
                    if(parseInt(this.grupo_porcentaje, 10) > 100 ){
                        Notify.ErrorToast("Se supera el 100% del pago de empresas.");
                    }
                    else{
                        const emp = this.grupo_registros_patronales.find(element => element.id == this.grupo_registro_patronal_id);
                        let grp = {id:0,empresa_id:this.grupo_registro_patronal_id, porcentaje: this.grupo_porcentaje, 
                            empresa:{nombre: emp.nombre}}
                        this.empleadoEmpresas.push(grp);

                        let index = this.grupo_registros_patronales.indexOf(emp);
                        this.grupo_registros_patronales.splice(index, 1);

                        this.grupo_registro_patronal_id = null;
                        this.grupo_porcentaje = null;

                        

                    }  
                }
            }
        },
        eliminarEmpresa(index,item){
            let id_grup = {"id":item.id};
            this.empleado_empresas_eliminadas.push(id_grup);
            //let empresa = this.empleado.empleadoEmpresas[index].empresa_id;
            let empresaTemporal =  this.grupo_registros_patronales2.find(empresa => empresa.id === item.empresa_id);
            if(empresaTemporal != undefined){
                this.grupo_registros_patronales.unshift(empresaTemporal);
            }
            this.empleadoEmpresas.splice(index,1);
            this.grupo_registros_patronales.push({nombre:item.empresa.nombre,id:item.empresa_id});
        },
        /* getTiposNominas(){
            this.$apollo.queries.tipo_nominas.skip = false;
        }, */
        respuesta_error(err) {
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },
        async scrollPerfect(){
            var myCustomScrollbar = document.querySelector('.scrollbar-template');
            var ps = new PerfectScrollbar(myCustomScrollbar);

            var scrollbarY = myCustomScrollbar.querySelector('.ps__rail-y');

            myCustomScrollbar.onscroll = function () {
                scrollbarY.style.cssText = `
                                                top: ${this.scrollTop}px!important; 
                                                height: 200px; 
                                                background-color:transparent; 
                                                right: ${-this.scrollLeft}px
                                            `;
            }
        },
        abrirModalAgregar(tipo_modal=null) {
            this.componentKey += 1;
            this.finaliza_carga_agregar = true;
            this.resetValuesAgregar = false;
            if(tipo_modal == 'tPuesto'){
                this.titulo_modal_agregar = 'Agregar puesto';
                this.imgModalAgregar =  "/static/icon/puestosCreate.svg";
                this.tPuesto    = true;
                this.tEmpresa   = false;
                this.tSucursal  = false;
                this.tDeptos    = false;
                this.tReloj     = false;
                this.$refs.autoPuesto.isMenuActive = false;
            }
            else if(tipo_modal == 'tSucursal'){
                this.titulo_modal_agregar = 'Agregar sucursal';
                this.imgModalAgregar = "/static/modal/empresaSucursalCreate.svg";
                this.$session.set("idEmpresaEmpleado", this.empresa_value);
                this.tPuesto    = false;
                this.tEmpresa   = false;
                this.tSucursal  = true;
                this.tDeptos    = false;
                this.tReloj     = false;
                this.$refs.autoSucursal.isMenuActive = false;

            }
            else if(tipo_modal == 'tEmpresa'){
                this.titulo_modal_agregar = 'Agregar empresa';
                this.imgModalAgregar =  "/static/modal/empresaSucursalCreate.svg";
                this.$session.set("idEmpresaEmpleado", this.empresa_value);
                this.tPuesto    = false;
                this.tEmpresa   = true;
                this.tSucursal  = false;
                this.tDeptos    = false;
                this.tReloj     = false;
                this.$refs.autoEmpresa.isMenuActive = false;

            }
            else if(tipo_modal == 'tDepto'){
                this.titulo_modal_agregar = 'Agregar departamento';
                this.imgModalAgregar = "/static/modal/departamentoCreate.svg";
                this.tPuesto    = false;
                this.tEmpresa   = false;
                this.tSucursal  = false;
                this.tDeptos    = true;
                this.tReloj     = false;
                this.$refs.autoDepartamento.isMenuActive = false;
            }
            else if(tipo_modal == 'tHorario'){
                this.titulo_modal_agregar = "Registrar horario";
                this.imgModalAgregar = "/static/icon/horarioCreate.svg";
                this.tPuesto    = false;
                this.tEmpresa   = false;
                this.tSucursal  = false;
                this.tDeptos    = false;
                this.tReloj     = true;
                this.$refs.autoHorario.isMenuActive = false;
            }
            setTimeout(function() {
                document.getElementById("btnModal_agregar").click();
            }, 100);
        },
        obtener() {
            this.valor = true;
        },
        oyenteFalse(){
            this.loading_guardar_agregar = false;
            this.valor = false;
        },
        cerrarModalAgregar(){
            this.dialog_agregar = false;
            this.loading_guardar_agregar = false;
            this.resetValuesAgregar = true;
            this.idEmpresa = false;
        },
        guardarAgregar(valores){
            this.loading_guardar_agregar = true;
            if(valores.status == 'puesto'){
                let valoresPuesto = {
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                    funciones: valores.funciones,
                    id: 0,
                    nombre: valores.nombre,
                    objetivo: valores.objetivo,
                    objetivo_ias: valores.objetivo_ias,
                    plazas: valores.plazas,
                    sucursal_id: this.sucursal_value,
                    sueldo: valores.sueldo,
                    sueldo_maximo: valores.sueldo_maximo
                }
                apiPuestos.store(valoresPuesto).then((response) => {
                    Notify.Success("Operación exitosa", "Se creó el nuevo puesto satisfactoriamente.");
                    this.cerrarModalAgregar();
                    this.obtener_puestos();
                    this.empleado.puesto_id = response.id;
                    this.resetValuesAgregar = true;
                })
                .catch((err) => {
                    this.respuesta_error(err);
                    this.loading_guardar_agregar = false;
                });
                this.valor = false;
            }
            else if(valores.status == 'empresa'){
                valores.cliente_id = this.cliente_value;
                empresaApi.addEmpresa(valores).then((response) => {
                    Notify.Success("Operación exitosa", "Se creó la nueva empresa satisfactoriamente.");
                    this.cerrarModalAgregar();
                    this.obtener_empresas();
                    this.empresa_value = response.id;
                    this.resetValuesAgregar = true;
                })
                .catch((err) => {
                this.respuesta_error(err);
                this.loading_guardar_agregar = false;
                });
                this.valor = false;
            }
            else if(valores.status == 'sucursal'){
                let valoresSuc = {
                    cliente_id: this.cliente_value,
                    codigo_postal:"0",
                    colonia_id: valores.colonia_id,
                    coordenadas: valores.coordenadas,
                    cp_id: valores.cp_id,
                    descripcion: valores.descripcion,
                    direccion: valores.direccion,
                    distancia_checada: valores.distancia_checada,
                    empresa_id: this.empresa_value,
                    estado_id: valores.estado_id,
                    id: 0,
                    municipio_id: valores.municipio_id,
                    nombre: valores.nombre
                };
                sucursalApi.addSucursal(valoresSuc).then((response) => {
                    Notify.Success("Operación exitosa", "Se ha creado la sucursal satisfactoriamente.");
                    this.cerrarModalAgregar();
                    this.obtenerSucursales();
                    this.sucursal_value = response.id;
                })
                .catch((err) => {
                    this.respuesta_error(err);
                    this.loading_guardar_agregar = false;
                });
                this.valor = false;
            }
            else if(valores.status == 'depto'){
                let valoresDep= {
                    id: 0,
                    nombre: valores.nombre,
                    encargado: valores.encargado,
                    correo: valores.correo,
                    descripcion: valores.descripcion,
                    sucursal_id: this.sucursal_value,
                    empresa_id: this.empresa_value,
                    cliente_id: this.cliente_value
                };
                departamentoApi.addDepartamento(valoresDep).then((response) => {
                    Notify.Success("Operación exitosa", "Se ha creado el departamento satisfactoriamente.");
                    this.cerrarModalAgregar();
                    this.obtenerDepartamentos();
                    this.empleado.departamento_id = response.id;
                })
                .catch((err) => {
                    this.respuesta_error(err);
                    this.loading_guardar_agregar = false;
                });
                this.valor = false;
            }
            else if(valores.status == 'horarios'){        
                let valoresHorario = {
                    id:0,
                    nombre: valores.nombre,
                    descripcion: valores.descripcion,
                    minutos_tolerancia: valores.minutos_tolerancia,
                    detalles_horario: valores.detalles_horario,
                    detalles_eliminados: valores.detalles_eliminados,
                    jornada_id: valores.jornada_id,
                    cliente_id: this.cliente_value,
                    empresa_id: this.empresa_value,
                    sucursal_id: this.sucursal_value,
                };
                 if(valoresHorario.detalles_horario.length == 0 ){
                    this.valor = false;
                    this.loading_guardar_agregar = false;
                    Notify.ErrorToast("Es requerido agregar al menos un día con horario");
                }
                else{
                    horariosApi.addHorario(valoresHorario).then((response) => {
                        Notify.Success("Operación exitosa", "Se ha creado el horario satisfactoriamente.");
                        this.cerrarModalAgregar();
                        this.obtenerHorarios();
                        this.empleado.horario_id = response.id;
                    })
                    .catch((err) => {
                        this.respuesta_error(err);
                        this.loading_guardar_agregar = false;
                    });
                    this.valor = false;
                }
                
            }
        },
        resetValorGuardar(){
            this.valor = false;
        },
        async obtener_puestos(){
            let self = this;
            let parametros_base = { 
                activo: true, paginate: false, 
                order: "asc",
                cliente_id: this.cliente_value,
                empresa_id: this.empresa_value,
                sucursal_id: this.sucursal_value,
            };
            this.puestos = [];
            await apiPuestos.find(parametros_base).then(response => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.claveNombre = item.clave + ' - ' +  item.nombre;
                        this.puestos.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los puestos.");
            });
        },
        async obtener_empresas(){
            let self = this;
            let parametros = { activo: true, paginate: false, cliente_id: this.cliente_value, solo_pagos:false };
            await empresaApi.getEmpresas(parametros).then(response => {
                self.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        async obtenerSucursales(){
            let self = this;
            let parametros = { activo: true, paginate: false, cliente_id: this.datosLogin.cliente_id, empresa_id: this.empresa_value};
            await sucursalApi.getSucursales(parametros).then(response => {
                self.sucursales = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.")
            })
        },
        async obtenerDepartamentos(){
            let self = this;
            let parametros = { activo: true, paginate: false, sucursal_id: this.sucursal_value };
            await departamentoApi.getDepartamentos(parametros).then(response => {
                self.departamentos = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los departamentos.")
            })
        },
        async obtenerHorarios(){
            let self = this;
            let parametros = { activo: true, paginate: false, sucursal_id: this.sucursal_value };
            await horariosApi.getHorarios(parametros).then(response => {
                self.horarios =  response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los horarios");
            })
        },
        openModalEmpresa(type, itemEmpresa = null) {
            this.$refs.autoEmpresa.isMenuActive = false;
            let resolucion = screen.height;
            let alto = screen.height * 0.70;
            if (resolucion < 850) {
                alto = resolucion * 0.76;
            }

            const title = type == "update" ? "Actualizar Empresa" : "Registrar Empresa";

            this.$modal.show(ComponentOneEmpresa, {
                title: title,
                objEmpresa: itemEmpresa,
                action: type
            }, {
                name: 'empresaModal',
                height: alto,
                width: 850,
                scrollable: true
            }, {
                'before-close': (event) => {
                    this.isLoading = true;
                    this.obtener_empresas();
                    /* this.$apollo.queries.empresas.refetch(); */

                }
            });
        },
        async getRegistroPatronal(){
            this.registroPatronal = [];
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }
            await apiRegistroPatronal.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registroPatronal.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },
        async getTiposRegimenes(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            await apiTipoRegimen.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        if(
                            item.clave != '05' && 
                            item.clave != '06' &&
                            item.clave != '07' &&
                            item.clave != '08' &&
                            item.clave != '09' &&
                            item.clave != '10' &&
                            item.clave != '11'
                        ){
                            item.claveDescripcion = item.clave + ' - ' + item.descripcion
                            this.tiposRegimenesSAT.push(item);
                        }
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Tipos de regímenes.");
            });
        },
        getBrigadas(){
            this.$apollo.queries.brigadas.skip = false;
        },
        getCursosCapacitacion(){
            this.$apollo.queries.cursos_capacitaciones.skip = false;
        },
        async agregarCurso(){
            let self = this;
            if( this.curso_id   == null || this.curso_id   == "" ||
                this.evaluacion == null || this.evaluacion == "" )
            {
                Notify.ErrorToast("Favor de agregar todos los campos.");
            }
            else if(parseInt(this.evaluacion) < 0 || parseInt(this.evaluacion) > 100){
                Notify.Warning("La evaluación tiene que estar en el rango de 0 a 100")
            }
            else{
                const curso = await this.itemsCursosCapacitaciones.find(element => element.id == this.curso_id);
                let empleado_id = 0 
                if(this.action === 'update'){
                    empleado_id = this.empleado.id;
                }
                
                let cursos = {
                    id                      : 0,
                    empleado_id             : empleado_id,
                    curso_capacitacion_id   : this.curso_id, 
                    evaluacion              : parseInt(this.evaluacion), 
                    observaciones           : this.observaciones,
                    cursoCapacitacion       : {nombre: curso.nombre}
                }
                self.empleado.cursosCapacitaciones.push(cursos);

                self.itemsCapacitaciones.push(curso);
                self.itemsCursosCapacitaciones.splice(self.itemsCursosCapacitaciones.indexOf(curso),1);

                self.curso_id = null;
                self.evaluacion = null;
                self.observaciones = null;
            }
        },
        eliminaCurso(index,item){
            let id = {"id":item.id};
            this.cursos_capacitaciones_eliminadas.push(id);
            const curso = this.itemsCapacitaciones.find(element => element.id == item.curso_capacitacion_id);
            this.itemsCursosCapacitaciones.push(curso);
            this.empleado.cursosCapacitaciones.splice(index,1);
        },
        getTiposContratosSAT(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiTipoContratoSAT.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.tiposContratos.push(item)
                        //this.contratos.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Tipos de contratos.");
            });
        },
        async getLoadKardexCollections(){
            let parametros = { empleado_id:parseInt(this.objEmpleado.id), rol_name:this.rol}
            await apiEmpleados.getKardexCollections(parametros).then((response) => {
                this.colonias                   = response.colonias;
                this.clientes                   = response.clientes;
                this.empresas                   = response.empresas;
                this.sucursales                 = response.sucursales;
                this.departamentos              = response.departamentos;
                this.horarios                   = response.horarios;
                this.puestos                    = response.puestos;
                this.nivelesEstudios            = response.escolaridades;
                this.bancos                     = response.bancos;
                this.prestaciones               = response.prestaciones;
                this.puestos                    = response.puestos;
                this.itemsTipoEmpleado          = response.EmpleadoTipo;
                this.itemsTipoSalarioIMSS       = response.tipoSalarioIMSS;
                this.itemsSemanaIMSS            = response.semanaJornadasIMSS;
                this.itemsTipoTrabajadorIMSS    = response.tipoTrabajadorIMSS;
                //console.log("emp",this.empleadoEmpresas);
                //console.log("re",response.empresas);
                    let registros_patronales = [];
                    let agregar = false;
                    for(let rp of response.empresas){
                        agregar = true;
                        for(let emp of this.empleadoEmpresas){
                          if(rp.id == emp.empresa_id){
                              agregar = false;
                          }
                        }
                        if(agregar){
                            registros_patronales.push(rp);
                        }
                    }
                    //console.log("rp",registros_patronales);
                this.grupo_registros_patronales     = registros_patronales;
                this.estadosCFDI        = response.estados;
                this.itemsBrigadas      = response.Brigadas;
                this.itemsCursosCapacitaciones = response.Cursos;

                let formatRegistroPatronal = response.registros_patronales;
                formatRegistroPatronal.forEach( item => {
                    item.nombreClave = item.nombre + ' - ' + item.clave
                    this.registroPatronal.push(item);
                })
                
                let formatRegimenes = response.tiposRegimenes;              
                formatRegimenes.forEach( item => {
                    if(
                        item.clave != '05' && 
                        item.clave != '06' &&
                        item.clave != '07' &&
                        item.clave != '08' &&
                        item.clave != '09' &&
                        item.clave != '10' &&
                        item.clave != '11'
                    ){
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.tiposRegimenesSAT.push(item);
                    }
                })
                
                /*let formatContratos  = response.tipos_contratos;
                formatContratos.forEach( item => {
                    item.claveDescripcion = item.clave + ' - ' + item.descripcion
                    this.contratos.push(item);
                })*/
                this.contratos  = response.tipos_contratos;
                this.contratos.map(item => {
                    let rp = item.registro_patronal !== null ? " - "+item.registro_patronal.nombre:""
                    item.claveDescripcion = item.tipo_contrato.clave+" - "+item.nombre+rp;
                });

                let format_tipos_nominas = response.tipos_nominas;
                format_tipos_nominas.forEach( item => {
                    item.claveDescripcion = item.clave + ' - ' + item.descripcion
                    this.itemsTiposNominas.push(item);
                })
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los datos.");
            });
        },
        async getTiposNominas() {
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresa_value,
            }
            this.itemsTiposNominas = [];
            await apiTipoNomina.find(param).then((response) => {
                let format = response.data;
                if(format.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.itemsTiposNominas.push(item);
                    })
                }
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },
        async getContratosFormatos(){
            let parametros = { 
                activo:true, 
                paginate:false, 
                order:"asc", 
                registro_patronal_id:this.empleado.registro_patronal_id, 
                tipo:"EMPLEADOS", 
                sistema:'nominas',
                es_otro_documento: false,
                sat_tipo_contrato_id: this.contrato_sat_id
            }
            await contratosApi.find(parametros).then((response) => {
                this.contratos = response.data;
                this.contratos.map(item => {
                    let rp = item.nombre_registro_patronal !== null ? " - "+item.nombre_registro_patronal:""
                    item.claveDescripcion = item.clave_formato+" - "+item.nombre+rp;
                });
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los tipos de contratos.");
            });
        },
        async getTipoEmpleados() {
            let param = {
                activo      : true,
                paginate    : false,
                order       : 'asc'
            }
            await apiTipoEmpleados.find(param).then((response) => {
                this.itemsTipoEmpleado = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de empleado.");
            })
        },
        async getCategoriaEmpleados() {
            let param = {
                activo      : true,
                paginate    : false,
                order       : 'asc'
                
            }
            await apiCategoriaEmpleado.find(param).then((response) => {
                this.itemsCategoriasEmpleado = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar las categorías de empleado.");
            })
        },
        async getCentroCostos(empresaId) {
            let param = {
                activo      : true,
                paginate    : false,
                order       : 'asc',
                empresa_id  : empresaId
            }
            await apiCentroCosto.find(param).then((response) => {
                this.itemsCentroCosto = response.data;
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los centros de costo.");
            })
        },
        async getColeccionesIMSS(){
            await idseApi.getColeccionesIMSS().then(response => {
                this.itemsTipoSalarioIMSS       = response.tipos_salarios;
                this.itemsSemanaIMSS            = response.semanas_jornadas;
                this.itemsTipoTrabajadorIMSS    = response.tipos_trabajadores;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las colecciones de IMSS");
            });
        },
        async getHistorico(EmpleadoId){
            let param =  {
                empleado_id: EmpleadoId,
            }
            await apiEmpleados.getHistorico(param).then(response => {
                if(response.data.length>0){
                    this.tipo_baja_imss_id = response.data[0].tipo_baja_imss_id;
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar el historico del empleado.");
            });
        },
        async getBajaImss() {
            let param = { activo: true, paginate: false };
            await apiTipoBajaImss.find(param).then(response => {
                this.tipos_baja_imss = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los tipos de baja IMSS");
            });
        },
        getRegimenesFiscales(){
            let parametros = {
                activo      : true, 
                paginate    : false,
                order       : 'asc'
            }
            this.regimenesFiscales = [];
            apiRegimenFiscal.find(parametros).then(response =>{
                response.data.forEach( item => {
                    if(item.clave == '605'){
                        this.regimenesFiscales.push(item);
                    }
                })
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast(err.response.data.error);
            });
        },
        abrirModalColonia(){
            this.dialogColonia = true;
            this.isLoadingModalColonia = false;
        },
        cerrarModalColonia(){
            this.dialogColonia = false;
            this.nombreColonia = null;
            this.isSavingColonia = false;
        },
        guardarColonia(){
            this.$refs.formColonia.validate().then(success => {
                this.isSavingColonia = true;
                if(!success) {
                    this.isSavingColonia = false;
                    return;
                }
                
                let parametros = {
                    nombre : this.nombreColonia,
                    codigo_postal_id : this.empleado.cp_id
                };
                
                catalogoApis.addColonia(parametros).then((response) => {
                    Notify.Success("Operación exitosa", "La calonia se agrego correctamnte.");
                    this.busca_codigos()
                    this.cerrarModalColonia();
                    this.empleado.colonia_id = response.data.id;
                    this.$nextTick(() => {
                        this.$refs.formColonia.reset();
                    });
                })
                .catch(err => {
                    this.isSavingColonia = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },
        abrirModalSueldo(tipo){
            this.tipoSueldo = tipo;
            this.guardarEdicionSueldo = 'guardar';
            this.nombre_completo = this.empleado.nombre + ' ' + this.empleado.apellido_paterno + ' ' + (this.empleado.apellido_materno!=null?this.empleado.apellido_materno:'');

            this.colaborador.valor = this.empleado.salarioMinimo.nuevo_sueldo;
            this.colaborador.salario_base_cotizacion = this.empleado.salarioMinimo.nuevo_sbc;
            this.colaborador.sbc_fijo = this.empleado.salarioMinimo.nuevo_sbc_fijo;
            this.colaborador.sbc_variable = this.empleado.salarioMinimo.nuevo_sbc_variable;
            this.colaborador.fecha_de_aplicacion_anterior = this.empleado.salarioMinimo.fecha_de_aplicacion;
            let parametros = {
                empleado_id : parseInt(this.objEmpleado.id)
            };
            this.dialogSueldo = true;
            this.isLoadingModalSueldo = true;
            this.datosTabla = [];
            
            apiIncrementoSalarial.historial(parametros).then((response) => {
                this.isLoadingModalSueldo = false;
                this.datosTabla = response;
            })
            .catch(err => {
                this.isLoadingModalSueldo = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
            
        },
        cerrarModalSueldo(){
            this.dialogSueldo = false;
            this.guardarEdicionSueldo = 'guardar';
            this.colaborador            = {
                id                      : null,
                nombre_completo         : null,
                empleado_id             : null,
                salario_anterior        : null,
                sbc_anterior            : null,
                salario_nuevo           : null,
                sbc_nuevo               : null,
                fecha_de_aplicacion     : null,
                tipo                    : 'fijo',
                valor                   : null,
                salario_base_cotizacion : null,
                sbc_fijo                : null,
                sbc_variable            : null,
                nuevo_sbc_fijo          : null,
                nuevo_sbc_variable      : null,
                anterior_sbc_fijo       : null,
                anterior_sbc_variable   : null,
                fecha_de_aplicacion_anterior : null
            };
            this.$nextTick(() => {
               if(this.$refs.formIncremento){
                    this.$refs.formIncremento.reset();
                }
            })
        },
        async guardarSueldo(){
            this.colaborador.empleado_id            = parseInt(this.objEmpleado.id);
            this.colaborador.salario_anterior       = this.empleado.salarioMinimo.nuevo_sueldo;
            this.colaborador.sbc_anterior           = this.empleado.salarioMinimo.nuevo_sbc;
            this.colaborador.anterior_sbc_fijo      = this.empleado.salarioMinimo.nuevo_sbc_fijo;
            this.colaborador.anterior_sbc_variable  = this.empleado.salarioMinimo.nuevo_sbc_variable;
            this.colaborador.salario_nuevo          = this.colaborador.valor;
            this.colaborador.nuevo_sbc_fijo         = this.colaborador.sbc_fijo;
            this.colaborador.nuevo_sbc_variable     = this.colaborador.sbc_variable;
            
            /* if(this.tipoSueldo == 'sueldo'){
                if(
                    this.colaborador.sbc_fijo == null || 
                    this.colaborador.sbc_fijo == 0 || 
                    this.colaborador.sbc_fijo == '0'
                ){
                    await this.consultarSueldo();
                }else{
                    this.colaborador.salario_nuevo      = this.colaborador.valor;
                    this.colaborador.nuevo_sbc_fijo     = this.colaborador.sbc_fijo;
                    this.colaborador.nuevo_sbc_variable = this.colaborador.sbc_variable;
                }
            }
            else if(this.tipoSueldo == 'SBC'){
                if(
                    this.colaborador.sbc_fijo == null || 
                    this.colaborador.sbc_fijo == 0 || 
                    this.colaborador.sbc_fijo == '0'
                ){
                    await this.consultarSueldo();
                }else{
                    this.colaborador.salario_nuevo      = this.colaborador.valor;
                    this.colaborador.nuevo_sbc_fijo     = this.colaborador.sbc_fijo;
                    this.colaborador.nuevo_sbc_variable = this.colaborador.sbc_variable;
                }
            } */

            let parametros = {
                empleados : [
                    this.colaborador
                ],
                sistema:'nominas',
                tipo: 'unico'
            }
            this.isSavingSueldo = true;
            apiIncrementoSalarial.guardar(parametros).then((response)=>{
                Notify.Success("Operación exitosa", "La operación se realizó correctamente.");
                this.isSavingSueldo = false;
                this.datosTabla = [];
                this.cerrarModalSueldo();
                this.$apollo.queries.empleado.refetch();
            })
            .catch(err => {
                this.isSavingSueldo = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },

        calcularSBC(){
            let parametros = { 
                empleados:[
                    {
                        empleado_id:parseInt(this.objEmpleado.id), 
                        fecha_de_aplicacion: this.colaborador.fecha_de_aplicacion, 
                        tipo: this.colaborador.tipo, 
                        valor: this.colaborador.valor,
                    }
                ],
                sistema:'nominas' 
            };
            this.isLoadingSBC = true;
            this.tipoCalculoSBC = 'automatico'

            apiIncrementoSalarial.consultar(parametros).then((response)=>{
                this.colaborador.salario_base_cotizacion    = response[0].sbc_nuevo;
                this.colaborador.nuevo_sbc_fijo             = response[0].nuevo_sbc_fijo;
                this.colaborador.sbc_fijo                   = response[0].nuevo_sbc_fijo;
                this.colaborador.nuevo_sbc_variable         = response[0].nuevo_sbc_variable;
                this.colaborador.sbc_variable               = response[0].nuevo_sbc_variable;
                this.isLoadingSBC = false;
            })
            .catch(err => {
                this.isLoadingSBC = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
            
        },

        calcularSBCblur(){

            

            let parametros = { 
                empleados:[
                    {
                        empleado_id         : parseInt(this.objEmpleado.id), 
                        fecha_de_aplicacion : this.colaborador.fecha_de_aplicacion, 
                        tipo                : this.colaborador.tipo, 
                        valor               : this.colaborador.valor,
                        fijo                : this.colaborador.sbc_fijo,
                        variable            : this.colaborador.sbc_variable,
                        calculo             : 'manual'
                    }
                ],
                sistema:'nominas'
            };
            this.isLoadingSBC = true;
            this.isSavingSueldo = true;
            this.tipoCalculoSBC = 'manual';

            apiIncrementoSalarial.consultar(parametros).then((response)=>{
                this.colaborador.salario_base_cotizacion = response[0].sbc_nuevo;
                this.isLoadingSBC = false;
                this.isSavingSueldo = false;
            })
            .catch(err => {
                this.isLoadingSBC = false;
                this.isSavingSueldo = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
            
        },

        async consultarSueldo(){
            let parametros = { 
                empleados:[
                    {
                        empleado_id:parseInt(this.objEmpleado.id), 
                        fecha_de_aplicacion: this.colaborador.fecha_de_aplicacion, 
                        tipo: this.colaborador.tipo, 
                        valor: this.colaborador.valor,
                    }
                ],
                sistema:'nominas'
            };
            await apiIncrementoSalarial.consultar(parametros).then((response)=>{
                this.colaborador.salario_nuevo      = response[0].salario_nuevo;
                this.colaborador.nuevo_sbc_fijo          = response[0].nuevo_sbc_fijo;
                this.colaborador.nuevo_sbc_variable          = response[0].sbc_variable;
            })
            .catch(err => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        editarModalSueldo(itemSueldo){
            this.guardarEdicionSueldo = 'edicion';
            this.colaborador.id                     = itemSueldo.id;
            this.colaborador.empleado_id            = itemSueldo.empleado_id;
            this.colaborador.fecha_de_aplicacion_anterior = itemSueldo.fecha_de_aplicacion_anterior;
            this.colaborador.fecha_de_aplicacion    = itemSueldo.fecha_de_aplicacion;
            this.colaborador.valor                  = itemSueldo.nuevo_sueldo;
            this.colaborador.sbc_fijo               = itemSueldo.nuevo_sbc_fijo;
            this.colaborador.sbc_variable           = itemSueldo.nuevo_sbc_variable;
        },
        eliminarModalSueldo(sueldo){
            Notify.Alert(
                "¿Estás seguro de eliminar el registro?",
                "",
                "Eliminar",
                () => {
                    apiIncrementoSalarial.eliminarRegistro(sueldo.id).then(async response => {
                        Notify.Success("Operación exitosa", "El registro ha sido eliminado satisfactoriamente");

                        this.empleado.salarioMinimo.nuevo_sueldo        = response.sueldo;
                        this.empleado.salarioMinimo.nuevo_sbc           = response.sbc;
                        this.empleado.salarioMinimo.nuevo_sbc_fijo      = response.salario_fijo;
                        this.empleado.salarioMinimo.nuevo_sbc_variable  = response.salario_variable;
                        this.colaborador.valor                          = response.sueldo;
                        this.colaborador.sbc_fijo                       = response.salario_fijo;
                        this.colaborador.sbc_variable                   = response.salario_variable;
                        this.colaborador.salario_base_cotizacion        = response.sbc;

                        await this.abrirModalSueldo(this.tipoSueldo)
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        async guardarEdicion(){
            let parametros = { 
                id                  : this.colaborador.id,
                empleado_id         : parseInt(this.objEmpleado.id), 
                fecha_de_aplicacion : this.colaborador.fecha_de_aplicacion,
                salario_nuevo       : this.colaborador.valor,
                nuevo_sbc_fijo      : this.colaborador.sbc_fijo,
                nuevo_sbc_variable  : this.colaborador.sbc_variable,
                fecha_de_aplicacion_anterior : this.colaborador.fecha_de_aplicacion
            };
            this.isSavingSueldo = true;
            await apiIncrementoSalarial.updateHistorial(parametros).then(async (response)=>{
                Notify.Success("Operación exitosa", "La actualizacion se realizó satisfactoriamente.");
                this.colaborador            = {
                    id                      : null,
                    nombre_completo         : null,
                    empleado_id             : null,
                    salario_anterior        : null,
                    sbc_anterior            : null,
                    salario_nuevo           : null,
                    sbc_nuevo               : null,
                    fecha_de_aplicacion     : null,
                    tipo                    : 'fijo',
                    valor                   : null,
                    salario_base_cotizacion : null,
                    sbc_fijo                : null,
                    sbc_variable            : null,
                    nuevo_sbc_fijo          : null,
                    nuevo_sbc_variable      : null,
                    anterior_sbc_fijo       : null,
                    anterior_sbc_variable   : null,
                    fecha_de_aplicacion_anterior : null
                };
                this.$nextTick(() => {
                if(this.$refs.formIncremento){
                        this.$refs.formIncremento.reset();
                    }
                })
                this.isSavingSueldo = false;
                await this.$apollo.queries.empleado.refetch();
                await this.abrirModalSueldo('sueldo');
            })
            .catch(err => {
                this.isSavingSueldo = false;;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        cancelarEdicion(){
            
            this.colaborador            = {
                id                      : null,
                nombre_completo         : null,
                empleado_id             : null,
                salario_anterior        : null,
                sbc_anterior            : null,
                salario_nuevo           : null,
                sbc_nuevo               : null,
                fecha_de_aplicacion     : null,
                tipo                    : 'fijo',
                valor                   : null,
                salario_base_cotizacion : null,
                sbc_fijo                : null,
                sbc_variable            : null,
                fecha_de_aplicacion_anterior: null
            };
            this.$nextTick(() => {
            if(this.$refs.formIncremento){
                    this.$refs.formIncremento.reset();
                }
            })
            this.guardarEdicionSueldo = 'guardar';
        },
        dateFormat(fecha, tipo = 'tabla') {
            if(fecha == null){
                return;
            }
            let fecha2 = fecha.split("-");
            if(tipo == 'tabla'){
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
            else{
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }
            
        },
        consultarPreSBCF(tipo){
            let parametros = {
                tipo_nomina_id      : this.empleado.tipo_nomina_id,
                prestacion_id       : this.empleado.prestacion_id,
                salario_diario      : this.empleado.salarioMinimo.nuevo_sueldo,
                fecha_alta_imss     : this.empleado.fecha_alta_imss,
                tipo                : tipo,
                salario_fijo        : this.empleado.salarioMinimo.nuevo_sbc_fijo,
	            salario_variable    : this.empleado.salarioMinimo.nuevo_sbc_variable
            }
            this.loadingPreSBCF = true;
            apiIncrementoSalarial.consultarPreSBCF(parametros).then(response => {
                this.empleado.salarioMinimo.nuevo_sbc_fijo      = response.salario_fijo;
                this.empleado.salarioMinimo.nuevo_sbc_variable  = response.salario_variable;
                this.sbc_consulta           = response.sbc;

                this.loadingPreSBCF = false;
            })
            .catch(err => {
                this.loadingPreSBCF = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async getDosContratos(){
            this.activar_dos_contratos = false;
            if(this.empleado.registro_patronal_id == null){
                return;
            }

            await apiRegistroPatronal.get(this.empleado.registro_patronal_id).then((response) => {
                this.activar_dos_contratos = response.activar_dos_contratos
            })
            .catch((error) => {
                console.error(error);
                console.log("Ocurrió un error al solitar RP - activar dos contratos");
            });
        },
        
    },
    apollo: {
        empleado: {
            query       : queries.getEmpleadoQuery,
            fetchPolicy : 'no-cache',
            variables() {
                return {
                    id : parseInt(this.objEmpleado.id)
                }
            },
            skip() {
                return true
            },
            error(error){
                console.log("Se presento un error en query de empleado.");
            },
            result({loading, error}) {
                //this.getParam();
                //this.getCP(this.empleado.cp_id);
                this.$session.set('empleado_update',this.empleado);
                this.cliente_value          = this.empleado.cliente_id;
                this.empresa_value          = this.empleado.empresa_id;
                this.sucursal_value         = this.empleado.sucursal_id;
                this.departamento_value     = this.empleado.departamento_id;
                this.empleado.foto_default  = this.empleado.foto;
                this.fecha_nacimiento       = this.empleado.fecha_nacimiento;
                this.contratoEmpleado.empleado_id       = this.empleado.id;
                this.contratoEmpleado.tipo_contrato_id  = this.empleado.tipo_contrato_id;
                this.contratoEmpleado.dias_contrato     = this.empleado.dias_contrato;
                this.contratoEmpleado.f_imss            = this.empleado.fecha_alta_imss;
                this.contratoEmpleado.f_ingreso         = this.empleado.fecha_ingreso;
                this.rpVal                              = this.empleado.registro_patronal_id;
                //this.empleadoEmpresas       = JSON.parse(JSON.stringify(this.empleado.empleadoEmpresas));
                if(this.$refs.FechaNacimiento){
                    setTimeout(()=>this.$refs.FechaNacimiento.setDate(this.empleado.fecha_nacimiento),200);
                }
                if(this.$refs.FechaIngreso){
                    setTimeout(()=>this.$refs.FechaIngreso.setDate(this.empleado.fecha_ingreso),200);
                }
                if(this.$refs.FechaDescuento){
                    setTimeout(()=>this.$refs.FechaDescuento.setDate(this.empleado.fecha_inicio_descuento),200);
                }
                if(this.$refs.FechaImss){
                    setTimeout(()=>this.$refs.FechaImss.setDate(this.empleado.fecha_alta_imss),200);
                }

                if(this.empleado.codigoPostalTimbre == null){
                    this.empleado.codigoPostalTimbre = {valor:null}
                }
                
                this.valorCliente           = this.empleado.cliente_id;
                this.valorEmpresa           = this.empleado.empresa_id;
                this.valorSucursal          = this.empleado.sucursal_id;
                if(this.action == "update"){
                    this.nombreFotoUptade = this.urlImg + this.empleado.foto;
                    this.busca_cp   = this.empleado.codigoPostal.nombre;
                    this.estado     = this.empleado.estado.nombre;
                    this.municipio  = this.empleado.municipio.nombre;

                    this.empleado.tipo_trabajador_imss_id   = this.empleado.empleadoIMSS.IMSS_tipo_trabajador_id;
                    this.empleado.tipo_salario_imss_id      = this.empleado.empleadoIMSS.IMSS_tipo_salario_id;
                    this.empleado.semana_imss_id            = this.empleado.empleadoIMSS.IMSS_semana_jornada_id;

                    if(this.empleado.centroCostos != null){
                        this.centroCostoId = this.empleado.centroCostos.id
                    }

                    //this.empleado.sbc_fijo                  = this.empleado.salarioMinimo.nuevo_sbc_fijo;
                    //this.empleado.sbc_variable              = this.empleado.salarioMinimo.nuevo_sbc_variable;

                    this.cargaModal = true;
                    this.getTiposDocumentos(this.empleado.empresa_id);
                    this.getCentroCostos(this.empleado.empresa_id);
                    this.cuentasBancarias = JSON.parse(JSON.stringify(this.empleado.cuentasBancarias));

                    if(this.empleado.cursosCapacitaciones.length>0){
                        this.empleado.cursosCapacitaciones.forEach(itemCurso => {
                            const curso = this.itemsCursosCapacitaciones.find(element => element.id == itemCurso.curso_capacitacion_id);
                            if(curso != undefined){
                                this.itemsCapacitaciones.push(curso);
                                this.itemsCursosCapacitaciones.splice(this.itemsCursosCapacitaciones.indexOf(curso),1);
                            }
                        })
                    }
                }

                if(this.empleado.baja){
                    this.getHistorico(this.objEmpleado.id)
                    this.getBajaImss();
                }

            }

        },
        brigadas: {
            query       : queriesBrigadas.getBrigadas,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    numberItems : 1000,
                    numberPage  : 1,
                    fieldOrder  : 'id',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.itemsBrigadas  = this.brigadas.data;
            },
        },
        cursos_capacitaciones: {
            query       : queriesCursos.getCursosCapacitaciones,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    numberItems : 1000,
                    numberPage  : 1,
                    fieldOrder  : 'id',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.itemsCursosCapacitaciones  = this.cursos_capacitaciones.data;
            },
        },
    },
    updated() {
        this.boolWatch  = true;
    },
    mounted() {
        this.validarImagen();
        this.scrollPerfect();
        this.getTiposContratosSAT();
        if(this.action != "update"){
            this.cargaModal = true;
        }
    },
    beforeMount() {
        if(this.objEmpleado != null) {
            //this.$apollo.queries.empleado.skip = false;
        } 
        else {
            this.empleado.estado_civil_id   = 1;

            if(this.$session.get('empleadoBorrador')){
                this.empleado.nombre                    =   this.$session.get('empleadoBorrador').nombre;
                this.empleado.apellido_paterno          =   this.$session.get('empleadoBorrador').apellido_paterno;
                this.empleado.apellido_materno          =   this.$session.get('empleadoBorrador').apellido_materno;
                this.empleado.fecha_nacimiento          =   this.$session.get('empleadoBorrador').fecha_nacimiento;
                this.empleado.sexo                      =   this.$session.get('empleadoBorrador').sexo;
                this.empleado.direccion                 =   this.$session.get('empleadoBorrador').direccion;
                this.empleado.estado_id                 =   this.$session.get('empleadoBorrador').estado_id;
                this.empleado.municipio_id              =   this.$session.get('empleadoBorrador').municipio_id;
                this.empleado.cp_id                     =   this.$session.get('empleadoBorrador').cp_id;
                this.empleado.colonia_id                =   this.$session.get('empleadoBorrador').colonia_id;
                this.empleado.telefono                  =   this.$session.get('empleadoBorrador').telefono;
                this.empleado.correo                    =   this.$session.get('empleadoBorrador').correo;
                this.empleado.estado_civil_id           =   this.$session.get('empleadoBorrador').estado_civil_id;
                //this.empleado.fecha_ingreso             =   
                setTimeout(()=>this.$refs.FechaIngreso.setDate(this.$session.get('empleadoBorrador').fecha_ingreso),200);
                setTimeout(()=>this.$refs.FechaImss.setDate(this.$session.get('empleadoBorrador').fecha_alta_imss),200);
                this.empleado.fecha_baja                =   this.$session.get('empleadoBorrador').fecha_baja;
                this.empleado.dia_descanso              =   this.$session.get('empleadoBorrador').dia_descanso;
                this.empleado.cliente_id                =   this.$session.get('empleadoBorrador').cliente_id;
                this.empleado.empresa_id                =   this.$session.get('empleadoBorrador').empresa_id;
                this.empleado.sucursal_id               =   this.$session.get('empleadoBorrador').sucursal_id;
                this.empleado.departamento_id           =   this.$session.get('empleadoBorrador').departamento_id;
                this.empleado.horario_id                =   this.$session.get('empleadoBorrador').horario_id;
                this.empleado.tiene_hijos               =   this.$session.get('empleadoBorrador').tiene_hijos;
                this.empleado.clave_interna             =   this.$session.get('empleadoBorrador').clave_interna;
                this.empleado.email_anterior            =   this.$session.get('empleadoBorrador').email_anterior;

                this.empleado.rfc                       =   this.$session.get('empleadoBorrador').rfc;
                this.empleado.curp                      =   this.$session.get('empleadoBorrador').curp;
                this.empleado.salarioMinimo.nuevo_sueldo=   this.$session.get('empleadoBorrador').salarioMinimo.nuevo_sueldo;
                this.empleado.salarioMinimo.nuevo_sbc   =   this.$session.get('empleadoBorrador').salarioMinimo.nuevo_sbc;
                
                this.empleado.imss                      =   this.$session.get('empleadoBorrador').imss;
                this.empleado.fecha_alta_imss           =   this.$session.get('empleadoBorrador').fecha_alta_imss;
                this.empleado.tipo_sangre               =   this.$session.get('empleadoBorrador').tipo_sangre;
                this.empleado.fecha_planta              =   this.$session.get('empleadoBorrador').fecha_planta;
                this.empleado.cuenta_fonacot            =   this.$session.get('empleadoBorrador').cuenta_fonacot;
                this.empleado.cuenta_infonavit          =   this.$session.get('empleadoBorrador').cuenta_infonavit;
                //this.empleado.fecha_inicio_descuento    =   
                setTimeout(()=>this.$refs.FechaDescuento.setDate(this.$session.get('empleadoBorrador').fecha_inicio_descuento),200);
                this.empleado.factor_descuento          =   this.$session.get('empleadoBorrador').factor_descuento;
                this.empleado.tipo_factor               =   this.$session.get('empleadoBorrador').tipo_factor;
                this.empleado.afore                     =   this.$session.get('empleadoBorrador').afore;
                this.empleado.nombre_contacto           =   this.$session.get('empleadoBorrador').nombre_contacto;
                this.empleado.telefono_contacto         =   this.$session.get('empleadoBorrador').telefono_contacto;
                this.empleado.observaciones             =   this.$session.get('empleadoBorrador').observaciones;
                this.empleado.nivel_estudios_id         =   this.$session.get('empleadoBorrador').nivel_estudios_id;
                this.empleado.puesto_id                 =   this.$session.get('empleadoBorrador').puesto_id;
                this.getParam();
                this.cliente_value                      =   this.$session.get('empleadoBorrador').cliente_id;
                this.empresa_value                      =   this.$session.get('empleadoBorrador').empresa_id;
                this.sucursal_value                     =   this.$session.get('empleadoBorrador').sucursal_id;
                this.departamento_value                 =   this.$session.get('empleadoBorrador').departamento_id;
                //this.fecha_nacimiento                   =   this.$session.get('empleadoBorrador').fecha_nacimiento;
                setTimeout(()=>this.$refs.FechaNacimiento.setDate(this.$session.get('empleadoBorrador').fecha_nacimiento),200);

                this.empleado.nacionalidad              =   this.$session.get('empleadoBorrador').nacionalidad;
                this.empleado.lugar_nacimiento          =   this.$session.get('empleadoBorrador').lugar_nacimiento;
                this.empleado.celular                   =   this.$session.get('empleadoBorrador').celular;
                this.empleado.observacion_salud         =   this.$session.get('empleadoBorrador').observacion_salud;
                this.empleado.conocimientos_extras      =   this.$session.get('empleadoBorrador').conocimientos_extras;
                this.empleado.direccion_laboral         =   this.$session.get('empleadoBorrador').direccion_laboral;
                this.empleado.tipo_pago                 =   this.$session.get('empleadoBorrador').tipo_pago;
                this.empleado.codigoPostalTimbre.valor  =   this.$session.get('empleadoBorrador').codigoPostalTimbre.valor;
                this.empleado.entidad_cfdi              =   this.$session.get('empleadoBorrador').entidad_cfdi;
                this.empleado.registro_patronal_id      =   this.$session.get('empleadoBorrador').registro_patronal_id;
                this.empleado.tipo_nomina_id            =   this.$session.get('empleadoBorrador').tipo_nomina_id;
                this.empleado.prestacion_id             =   this.$session.get('empleadoBorrador').prestacion_id;
                this.empleado.dias_vacaciones           =   this.$session.get('empleadoBorrador').dias_vacaciones;
                this.empleado.vacaciones_fiscales       =   this.$session.get('empleadoBorrador').vacaciones_fiscales;
                this.empleado.empleado_tipo_id          =   this.$session.get('empleadoBorrador').empleado_tipo_id;
                this.empleado.ubicacion_laboral         =  this.$session.get('empleadoBorrador').ubicacion_laboral
                this.empleado.clave_elector         =  this.$session.get('empleadoBorrador').clave_elector
                this.empleado.otros_ingresos         =  this.$session.get('empleadoBorrador').otros_ingresos
                this.busca_cp                           =   this.$session.get('kardexCP');
                if(this.archivoImagen != null){
                    formData.append('archivo', this.archivoImagen);
                }

                this.empleado.dias_contrato             =   this.$session.get('empleadoBorrador').dias_contrato;
                this.empleado.tipo_contrato_id          =   this.$session.get('empleadoBorrador').tipo_contrato_id;
                this.empleado.tipo_regimen_sat_id       =   this.$session.get('empleadoBorrador').tipo_regimen_sat_id;
                this.empleado.regimen_fiscal_id         =   this.$session.get('empleadoBorrador').regimen_fiscal_id;
            }
        }
    },

    async created(){
        this.init();
        this.empleado.foto  = null;
        this.imgFoto        = null;
        this.datosLogin     = this.$session.get('usuario');
        this.rol            = this.datosLogin.rol.name;       
        this.getRegimenesFiscales();

        if(this.action=="create"){
            this.imagenModal = '/static/modal/empleadoCreate.svg';
            await this.getParam();
            await this.getEstadosCiviles();
            if(this.rol == 'root'){
                await this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if(this.rol == 'admin-empresa'){
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            else if (this.rol == 'admin-sucursal'){
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value= this.datosLogin.sucursal_id;
            }
            
            await this.getEstadosCFDI();
            await this.getNivelEstudios();
            //await this.getEmpresasGrupo();
            //await this.getTiposContratosSAT();
            this.getTiposRegimenes();
            this.getBrigadas();
            this.getCursosCapacitacion();
            this.getTipoEmpleados();
            this.getColeccionesIMSS();
            this.getCategoriaEmpleados();
        }
        else if(this.action=="update"){
            this.imagenModal = '/static/modal/empleadoUpdate.svg';
            await this.getTiposRegimenes();
            this.boolWatch = false;
            await this.getLoadKardexCollections();
            this.getCategoriaEmpleados();
        }

        if(this.objEmpleado != null) {
            this.$apollo.queries.empleado.skip = false;
        } 
    }
}
</script>

<style scoped>
.titleModalTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '600';
        font-size: 18px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .textTimbre{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: '500';
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    #loading{
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .header {
        text-align: center !important;
        padding-top: 50px;
        margin-bottom: -30px;
    }

    .title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
        color: #101C5A;
    }
    .text-green input{
        color: green !important;
    }
    .tituloRadio{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #828282;
        padding-bottom: 10px;
    }
    .subtitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #1E2245
    }

    /**
     * TABLE DOCUMENTOS
     */
     th, td {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;

        text-align: left !important;
    }

    tbody tr:nth-child(1n){
        /* background-color: #f2f2f2; */
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }

    .tbl-documentos {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-documentos .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
        /* border-top-left-radius: 20px;
        border-top-right-radius: 20px; */
    }
    .scrollbar-template {
        position: relative;
    }
    .cursor-pointer{
      cursor:pointer;
    }
    #btnModal_agregar{
      display: none;
    }
    /* Estilos subir imagen */
    .btn-group {
        min-width: 80px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    .btn-group a:hover {
        text-decoration: none;
    }
    .dropdown-toggle {
        font-family: "Montserrat" !important;
        font-size: 16px !important;
        border: 0.5px solid rgba(0, 0, 0, 0.38);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 9px 45px 8px 15px;
        width: 248px !important;
        height: 56px !important;
        outline: 0;
        color: rgba(0, 0, 0, 0.6);
        min-width: 80px;
        background-color: #FFFFFF;
        transition: background 0s ease-out;
        float: none;
        box-shadow: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        letter-spacing: normal;
        text-align: left;
        line-height: 36px;
    }
    .dropdown-toggle:hover {
        border: 0.5px solid rgba(0, 0, 0, 0.87);
        color: rgba(0, 0, 0, 0.87);
        background: #FFFFFF;
        cursor: pointer;
    }
    .dropdown-menu {
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 1000;
        float: left;
        min-width: 260px;
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #FFF;
    }
    .dropdown-menu > li > a {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #96999A;
        padding: 10px 30px;
        display: block;
        clear: both;
        white-space: nowrap;
        text-decoration: none;
    }
    .dropdown-menu > li > a:hover {
        background: #96999A;
    }
    .dropdown-menu > li {
        overflow: hidden;
        width: 100%;
        position: relative;
        margin-bottom: -29px;
    }
    .caret {
        color: #96999A;
        font-size: 18px;
        position: absolute;
        top: 7px;
        margin-left: -24px;
        margin-top: 4px;
        vertical-align: middle;
        right: 10px;
        line-height: 36px;
    }
    li {
        list-style: none;
    }
    .textoFoto {
        width: 284px;
        height: 30px;
        left: 218px;
        top: 518px;
        padding-left: 0px 65px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #96999A;
    }
    .btn-contenedor{
        padding-left: 20%;
        padding-right: 20%;
        text-align: center; 
        display: grid;
    }
    .btn-capturar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.2px;
        display: inline-block !important;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        background: #007EFF !important;
        box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16), inset 10px 11px 17px rgba(119, 124, 185, 0.11);
        border-radius: 80px;
        text-transform: none;
        margin-bottom: 5px !important;
    }
    .btn-omitir{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: inline-block !important;
        align-items: center;
        text-align: center;
        color: #96999A;
        background: transparent !important;
        border-radius: 80px;
        box-shadow: none !important;
        text-transform: none;
    }
    .imgFoto {
        float: left;
        width: 38px;
        height: 38px;
    }
    .titulo-foto {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #004baf;
        margin-top: 5px !important;
        margin-left: 10px !important;
        float: left;
    }
    /* Fin estilos subir imagen */
    .v-btn-cerrar .v-btn:hover::before {
      background-color: transparent !important;
      pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover::after {
      background-color: transparent !important;
      pointer-events: none;
    }
    .v-btn-cerrar .v-btn:hover {
      background-color: transparent !important;
      pointer-events: none;
    }
    .menuClassFoto{
        box-shadow: none;
    }
    .emptyTableDocumentos{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    #btnModalTimbre{
        display: none;
    }
    #btnModalColonia{
        display: none;
    }
    #btnModalSueldo{
        display: none;
    }
    .v-btn-hover:hover:before {
      background-color: transparent;
      
    }
    .v-btn-hover:before {
        display: none;
    }

    .v-btn-cerrar:hover:before {
      background-color: transparent;
      
    }
    .v-btn-cerrar:before {
        display: none;
    }


    .th-tabla{
        color:#828282;
        font-family: 'Montserrat';
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        padding: 8px;
        text-align: left;
    }

    .tbl-salario tbody{
        background:#FFFFFF;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        margin-bottom: 10em;
    }

    .tbl-salario tbody td{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #828282;
        text-align: left;
    }

    .tbl-salario {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-salario .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .firstth {
        border-top-left-radius: 20px !important;
    }
    .lastth {
        border-top-right-radius: 20px !important;
    }
    .emptyTableComplementos{
        color: #c5c5c5 !important;
        background:#FFFFFF;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 50px !important;
        padding-top: 50px !important;
        text-align: center !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }
    .thCheck{
        min-width: 56px;
    }
    .thTitulos{
        border-width:1px; 
        border-color:#E0E0E0 ;
    }
    .tdBlanco{
        color: #c5c5c5 !important;
        background:#FFFFFF;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 20px !important;
        padding-top: 20px !important;
        text-align: center !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }
    .trBody{
        border-width:1px; 
        border-color:transparent !important;
        border-bottom-style: solid ;
        margin-top: 10px!important;
        height: 42px;

        
    }
    .opcionesSalarios{
        display: flex;
        justify-content: center;
        padding: 0px;
    }
    .opcionesSalarios button{
        margin-right: 5px !important;
        margin-left: 5px !important;
    }


</style>